import { Avatar } from "primereact/avatar"
import React, { useEffect, useState } from "react"
import "./DetailPost.css"
import { useHistory, useLocation } from "react-router-dom"
import imageInvalid from "../../assets/images/invalid.jpg"
import { AutoComplete } from "primereact/autocomplete"
import { InputTextarea } from "primereact/inputtextarea"
import { createTag, getAllTags } from "../../service/tagAPI"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { TabMenu } from 'primereact/tabmenu';
import { removePost, updatePost } from "../../service/postAPI"
import { createProfile, getAllProfiles, updateProfile } from "../../service/profileAPI"
import { HighlightMenu, MenuButton, getSelectionDetails, useGetSelectionDetails } from "react-highlight-menu";
import axios from "axios"
import { createHistory } from "../../service/historyAPI"
import { Image } from "primereact/image"
import { Toast } from "primereact/toast"
import { useDispatch, useSelector } from "react-redux"
import { clearQueryStr } from "../../store/queryStore"
import ReactHtmlParser from "react-html-parser"
import { Dropdown } from "primereact/dropdown"
import { Button } from "primereact/button"
import { ConfirmPopup } from "primereact/confirmpopup" // To use <ConfirmPopup> tag
import { confirmPopup } from "primereact/confirmpopup" // To use confirmPopup method
import { getAllEntityNames } from "../../service/entityNameAPI"
import { getAllTopicTags } from "../../service/topicTagAPI"
DetailPost.propTypes = {}

function DetailPost({ data, toast, closeDialog }) {
  console.log("------------------------------------------------", data);
  let cateName = "Tin khác"
  if (data.category == "LotLoTaiLieu") {
    cateName = "Tin lọt lộ tài liệu"
  } else if (data.category == "KhacLienQuanQuanDoi") {
    cateName = "Tin khác liên quan tới quân đội"
  } else if (data.category == "ChongPhaQuanDoi") {
    cateName = "Tin liên quan tới hoạt động chống phá Quân đội"
  } else if (data.category == "ChongPhaDangNhaNuoc") {
    cateName = "Tin liên quan tới hoạt động chống phá Đảng Nhà Nước"
  }
  const queryClient = useQueryClient()
  const dispatch = useDispatch()
  const [desc, setDesc] = useState(data?.editedTextContent || "")
  const [title, setTitle] = useState(data?.title || "")
  const [violationcontent, setViolationcontent] = useState(data?.violationContent || "")
  const [violationenactment, setViolationenactment] = useState(data?.violationEnactment || "")
  const [contentCategory, setContentCategory] = useState({
    name: cateName,
    code: data.category,
  })
  const [violationTimes, setViolationTimes] = useState(data?.violationTimes || "")
  const [edit, setEdit] = useState(false)
  const [editTitle, setEditTitle] = useState(false)

  const [editViolationContent, setEditViolationContent] = useState(false)
  const [editViolationenactment, setEditViolationenactment] = useState(false)
  const [editViolationTimes, setEditViolationTimes] = useState(false)

  const [keywords, setKeywords] = useState("")
  const [keywordsTopicTag, setKeywordsTopicTag] = useState("")
  const [keywordsEntityName, setKeywordsEntityName] = useState("")

  const [isEntityNameMode, setIsEntityNameMode] = useState(false)
  const [activeIndex, setActiveIndex] = useState(0)
  const [contentEntityName, setContentEntityName] = useState("")
  const [listTextHighlight, setListTextHighlight] = useState(data?.entityNamesInfo)

  const [selection, setSelection] = useState({ text: '', start: 0, end: 0 });

  const category = [
    { name: "Tin liên quan tới hoạt động chống phá Đảng Nhà Nước", code: "ChongPhaDangNhaNuoc" },
    { name: "Tin liên quan tới hoạt động chống phá Quân đội", code: "ChongPhaQuanDoi" },
    { name: "Tin khác liên quan tới quân đội", code: "KhacLienQuanQuanDoi" },
    { name: "Tin lọt lộ tài liệu", code: "LotLoTaiLieu" },
    { name: "Tin khác", code: "TinKhac" },
  ]

  const [arrayTags, setArrayTags] = useState(
    [
      ...data?.tagsInfo?.map((p) => ({
        label: p.name,
        value: p.id,
      })),
    ] || [],
  )
  const [arrayTopicTags, setArrayTopicTags] = useState(
    [
      ...data?.topicTagsInfo?.map((p) => ({
        label: p.name,
        value: p.id,
      })),
    ] || [],
  )
  console.log(arrayTopicTags);
  const [arrayEntityNames, setArrayEntityNames] = useState(
    [
      ...data?.entityNamesInfo?.map((p) => ({
        label: p.entityNames.name,
        value: p.id,
      })),
    ] || [],
  )
  // const [arrayEntityNames, setArrayEntityNames] = useState(
  //    [],
  // )

  const [filterTags, setFilterTags] = useState([])
  const [filterTopicTags, setFilterTopicTags] = useState([])
  const [filterEntityNames, setFilterEntityNames] = useState([])
  const [keywordsProfile, setKeywordsProfile] = useState("")
  const [arrayProfiles, setArrayProfiles] = useState(
    [
      ...data?.profilesInfo?.map((p) => ({
        label: p.name,
        value: p.id,
      })),
    ] || [],
  )
  const [filterProfiles, setFilterProfiles] = useState([])
  const history = useHistory()
  const location = useLocation()
  const token = useSelector((state) => state.user.token)
  const userId = useSelector((state) => state.user.userData.id)

  const handleError = (err) => {
    if (err?.response?.data?.msg) {
      toast.current.show({ severity: "error", summary: err.response.data.msg, detail: "Lỗi" })
      throw new Error(err.response.data.msg)
    } else if (err?.message) {
      toast.current.show({ severity: "error", summary: err.message, detail: "Lỗi" })
      throw new Error(err.message)
    } else {
      toast.current.show({ severity: "error", summary: err, detail: "Lỗi" })
    }
  }
  const updateStatus = useMutation(updatePost, {
    onSuccess: () => {
      toast.current.show({ severity: "success", summary: "Cập nhật bài viết thành công", detail: "Thành công" })
    },
    onError: (error) => {
      handleError(error)
    },
    onSettled: () => {
      closeDialog()
      //   setDisplayDialog(false)
      history.push({
        pathname: location.pathname,
        search: ``,
      })
      return queryClient.invalidateQueries({
        predicate: (query) => {
          return query.queryKey.startsWith(`${process.env.REACT_APP_API_URL}/content`)
        },
      })
    },
  })
  const handlePost = (val) => {
    if (val === "cancelHandle") {
      updateStatus.mutate({
        id: data?.id,
        newData: {
          userHandle: "skippedPost",
          process: false,
        },
        token,
      })
    } else if (val === "handle") {
      updateStatus.mutate({
        id: data?.id,
        newData: {
          userHandle: "handledPost",
          process: true,
          violationEnactment:
            "Vi phạm điểm a, d, e khoản 1, Điều 5 Nghị định 72/2013/NĐ-CP ngày 15/7/2013 của Chính phủ về quản lý, cung cấp, sử dụng dịch vụ Internet và thông tin trên mạng.",
        },
        token,
      })
    }
  }
  const key = `${process.env.REACT_APP_API_URL}/tag?page=1&pageSize=12&name=${keywords}`
  const tags = useQuery(key, (query) => getAllTags(query, token), {
    onSuccess: (data) => {
      console.log(data);
      if (data)
        setFilterTags([
          ...data?.docs.map((p) => ({
            value: p.id,
            label: p.name,
          })),
        ])
    },
  })
  const topicTagKey = `${process.env.REACT_APP_API_URL}/topicTag?page=1&pageSize=12&name=${keywords}`
  const toppicTags = useQuery(topicTagKey, (query) => getAllTopicTags(query, token), {
    onSuccess: (data) => {
      console.log(data);
      if (data)
        setFilterTopicTags([
          ...data?.docs.map((p) => ({
            value: p.id,
            label: p.name,
          })),
        ])
    },
  })
  const keyEntityName = `${process.env.REACT_APP_API_URL}/entityName?page=1&pageSize=12&name=${keywords}`
  const entityNames = useQuery(keyEntityName, (query) => getAllEntityNames(query, token), {
    onSuccess: (data) => {
      console.log(data);
      if (data)
        setFilterEntityNames([
          ...data?.docs.map((p) => ({
            value: p.id,
            label: p.name,
          })),
        ])
    },
  })
  const keyProfile = `${process.env.REACT_APP_API_URL}/profile?page=1&pageSize=12&name=${keywordsProfile}`
  const profiles = useQuery(keyProfile, (query) => getAllProfiles(query, token), {
    onSuccess: (data) => {
      if (data)
        setFilterProfiles([
          ...data?.docs.map((p) => ({
            value: p.id,
            label: p.name,
          })),
        ])
    },
  })
  const addHistory = useMutation(createHistory, {
    onError: (e) => {
      console.log(e)
    },
  })
  const createTagFromContent = useMutation(createTag, {
    onError: (error) => handleError(error),
    onSuccess: (newData) => {
      let arrayTagsFormat = arrayTags.map((p) => p.value)
      arrayTagsFormat.push(newData?.doc?.id)
      updateContentMutation.mutate({
        id: data.id,
        newData: { tagIds: arrayTagsFormat },
        token,
      })
    },
    onSettled: () => {
      return queryClient.invalidateQueries({
        predicate: (query) => {
          return query.queryKey.startsWith(`${process.env.REACT_APP_API_URL}/tag`)
        },
      })
    },
  })

  const createTopicTagFromContent = useMutation(createTag, {
    onError: (error) => handleError(error),
    onSuccess: (newData) => {
      let arrayTopicTagsFormat = arrayTopicTags.map((p) => p.value)
      arrayTopicTagsFormat.push(newData?.doc?.id)
      updateContentMutation.mutate({
        id: data.id,
        newData: { topicTagIds: arrayTopicTagsFormat },
        token,
      })
    },
    onSettled: () => {
      return queryClient.invalidateQueries({
        predicate: (query) => {
          return query.queryKey.startsWith(`${process.env.REACT_APP_API_URL}/tag`)
        },
      })
    },
  })


  const createProfileFromContent = useMutation(createProfile, {
    onError: (error) => handleError(error),
    onSuccess: (newData) => {
      setArrayProfiles([
        ...arrayProfiles,
        {
          label: newData?.doc?.name,
          value: newData?.doc?.id,
        },
      ])
      const arrayProfilesFormat = arrayProfiles.map((p) => p.value)

      updateContentMutation.mutate({
        id: data.id,
        newData: { profileIds: [...arrayProfilesFormat, newData?.doc?.id] },
        token,
      })
    },
    onSettled: () => {
      return queryClient.invalidateQueries({
        predicate: (query) => {
          return query.queryKey.startsWith(`${process.env.REACT_APP_API_URL}/tag`)
        },
      })
    },
  })
  const updateContentMutation = useMutation(updatePost, {
    onSuccess: (updateData) => {
      toast.current.show({ severity: "success", summary: "Cập nhật thành công", detail: "Thành công" })

      addHistory.mutate({
        newData: {
          screen: "Bài viết",
          description: `Cập nhật bài viết id: ${updateData?.doc?.id} từ: {title: ${data?.title || ""},  userHandle: ${data?.userHandle
            },editedTextContent : ${data?.editedTextContent} , profileids: [${data?.profilesInfo?.map((p) => p?.id).join(", ") || ""
            }], tagids: [${data?.tagsInfo?.map((p) => p?.id)?.join(", ") || ""}],topicTagids: [${data?.tagsInfo?.map((p) => p?.id)?.join(", ") || ""
            }], violationContent: ${data?.violationContent
            }, entityNameIds: [${data?.entityNames?.map((p) => p?.id)?.join(", ") || ""}], violationEnactment: ${data?.violationEnactment || ""
            }, violationEnactment: ${data?.violationEnactment}, title: ${data?.title}} sang: { title: ${updateData?.doc?.title || ""
            }, userHandle: ${updateData?.doc?.userHandle},editedTextContent : ${updateData?.doc?.editedTextContent
            } , profileids: [${updateData?.doc?.profileIds.join(", ") || ""}], tagids: [${updateData?.doc?.tagIds.join(", ") || ""
            }], violationContent: ${updateData?.doc?.violationContent}, violationEnactment: ${updateData?.doc?.violationEnactment
            }, title: ${updateData?.doc?.title} }`,
        },
        token,
      })
    },
    onSettled: () => {
      return queryClient.invalidateQueries({
        predicate: (query) => {
          return query.queryKey.startsWith(`${process.env.REACT_APP_API_URL}/content`)
        },
      })
    },
  })
  // const deleteContent = useMutation(removePost, {
  //   onSuccess: (updateData) => {
  //     toast.current.show({severity: "success", summary: "Xóa bài viết thành công", detail: "Thành công"})
  //     closeDialog()
  //     console.log("updateData", updateData)
  //     console.log("data", data)
  //     addHistory.mutate({
  //       newData: {
  //         screen: "Bài viết",
  //         description: `Bỏ qua bài viết id: ${updateData?.doc[0]?.id} từ: {title: ${
  //           data?.title || ""
  //         }, editedTextContent : ${data?.editedTextContent} , profileids: [${
  //           data?.profilesInfo?.map((p) => p?.id).join(", ") || ""
  //         }], tagids: [${data?.tagsInfo?.map((p) => p?.id).join(", ") || ""}], violationContent: ${
  //           data?.violationContent
  //         }, violationEnactment: ${data?.violationEnactment}}`,
  //       },
  //       token,
  //     })
  //   },
  //   onSettled: () => {
  //     return queryClient.invalidateQueries({
  //       predicate: (query) => {
  //         return query.queryKey.startsWith(`${process.env.REACT_APP_API_URL}/content`)
  //       },
  //     })
  //   },
  // })
  const updateProfileFromContent = useMutation(updateProfile, {
    onError: (error) => handleError(error),
  })
  const searchTags = (event) => {
    let timeout
    let query = event.query

    if (timeout) {
      clearTimeout(timeout)
      timeout = null
    }
    timeout = setTimeout(() => {
      setKeywords(query)
    }, 300)
  }

  const searchTopicTags = (event) => {
    let timeout
    let query = event.query

    if (timeout) {
      clearTimeout(timeout)
      timeout = null
    }

    timeout = setTimeout(() => {
      setKeywordsTopicTag(query)
    }, 300)
  }


  const searchEntityNames = (event) => {
    let timeout
    let query = event.query

    if (timeout) {
      clearTimeout(timeout)
      timeout = null
    }
    timeout = setTimeout(() => {
      setKeywordsEntityName(query)
    }, 300)
  }

  const enterNoExit = (e) => {
    if (e.charCode == 13 && filterTags.length == 0 && e.target.value.trim()) {
      setArrayTags([
        ...arrayTags,
        {
          label: e.target.value,
          value: e.target.value,
        },
      ])
      createTagFromContent.mutate({ newData: { name: e.target.value }, token })
      e.target.value = ""
    }
  }

  const enterNoExitTopicTag = (e) => {
    if (e.charCode == 13 && filterTopicTags.length == 0 && e.target.value.trim()) {
      setArrayTopicTags([
        ...arrayTopicTags,
        {
          label: e.target.value,
          value: e.target.value,
        },
      ])
      createTopicTagFromContent.mutate({ newData: { name: e.target.value }, token })
      e.target.value = ""
    }
  }

  const enterNoExitEntityName = (e) => {
    if (e.charCode == 13 && filterEntityNames.length == 0 && e.target.value.trim()) {
      setArrayEntityNames([
        ...arrayEntityNames,
        {
          label: e.target.value,
          value: e.target.value,
        },
      ])
      e.target.value = ""
    }
  }

  const unSelectTag = (e) => {
    console.log(arrayTags);
    const newArrayTags = arrayTags.filter((p) => p.value !== e.value.value)
    setArrayTags(newArrayTags)
    const arrayTagsFormat = newArrayTags.map((p) => p.value)
    let updateData = { tagIds: arrayTagsFormat }
    if (e.value.value === "878aa7a3-8691-49b9-8018-2159a8b55175") {
      updateData.meta = null
    }
    updateContentMutation.mutate({
      id: data.id,
      newData: updateData,
      token,
    })
  }

  const unSelectTopicTag = (e) => {
    console.log(arrayTopicTags);
    const newArrayTopicTags = arrayTopicTags.filter((p) => p.value !== e.value.value)
    setArrayTopicTags(newArrayTopicTags)
    const arrayTopicTagsFormat = newArrayTopicTags.map((p) => p.value)
    updateContentMutation.mutate({
      id: data.id,
      newData: { topicTagIds: arrayTopicTagsFormat },
      token,
    })
  }

  const unSelectEntityName = (e) => {
    console.log(arrayEntityNames);
    const newArrayEntityNames = arrayEntityNames.filter((p) => p.value !== e.value.value)
    setArrayEntityNames(newArrayEntityNames)
    const arrayEntityNamesFormat = newArrayEntityNames.map((p) => p.value)
    updateContentMutation.mutate({
      id: data.id,
      newData: { entityNameIds: arrayEntityNamesFormat },
      token,
    })
  }

  const selectTag = (e) => {
    if (!arrayTags.find((p) => p.value.trim() === e?.value?.value?.trim())) {
      const newArrayTags = [...arrayTags, e.value]
      setArrayTags(newArrayTags)
      const arrayTagsFormat = newArrayTags.map((p) => p.value)
      let updateData = { tagIds: arrayTagsFormat }
      if (e.value.value === "878aa7a3-8691-49b9-8018-2159a8b55175") {
        updateData.meta = 100
      }

      updateContentMutation.mutate({
        id: data.id,
        newData: updateData,
        token,
      })
    }
  }

  const selectTopicTag = (e) => {
    if (!arrayTopicTags.find((p) => p.value.trim() === e?.value?.value?.trim())) {
      const newArrayTopicTags = [...arrayTopicTags, e.value]
      setArrayTopicTags(newArrayTopicTags)
      const arrayTopicTagsFormat = newArrayTopicTags.map((p) => p.value)
      updateContentMutation.mutate({
        id: data.id,
        newData: { topicTagIds: arrayTopicTagsFormat },
        token,
      })
    }
  }

  const selectEntityName = (e) => {
    if (!arrayEntityNames.find((p) => p.value.trim() === e?.value?.value?.trim())) {
      const newArrayEntityNames = [...arrayEntityNames, e.value]
      setArrayEntityNames(newArrayEntityNames)
      const arrayEntityNamesFormat = newArrayEntityNames.map((p) => p.value)
      updateContentMutation.mutate({
        id: data.id,
        newData: { entityNameIds: arrayEntityNamesFormat },
        token,
      })
    }
  }
  const searchProfiles = (event) => {
    let timeout
    let query = event.query

    if (timeout) {
      clearTimeout(timeout)
      timeout = null
    }
    timeout = setTimeout(() => {
      setKeywordsProfile(query)
    }, 300)
  }
  const enterNoExitProfiles = (e) => {
    if (e.charCode == 13 && filterProfiles.length == 0 && e.target.value.trim()) {
      setArrayProfiles([
        ...arrayProfiles,
        {
          label: e.target.value,
          value: e.target.value,
        },
      ])
      createProfileFromContent.mutate({ newData: { name: e.target.value }, token })
      e.target.value = ""
    }
  }
  const removeContentFromProfile = async (idSource, idProfile) => {
    const detailProfile = await axios.get(`${process.env.REACT_APP_API_URL}/profile/${idProfile}`, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    let sourceIds = detailProfile.data.doc.sourceIds || []
    let authorIds = detailProfile.data.doc.authorIds || []
    let contentIds = detailProfile.data.doc.contentIds || []
    let name = detailProfile.data.doc.name
    let description = detailProfile.data.doc.description

    if (contentIds && contentIds.length) {
      contentIds = contentIds.filter((p) => p != idSource)
    }
    updateProfileFromContent.mutate({
      id: idProfile,
      newData: { sourceIds, authorIds, contentIds, name, description },
      token,
    })
  }
  const addContentFromProfile = async (idContent, idProfile) => {
    const detailProfile = await axios.get(`${process.env.REACT_APP_API_URL}/profile/${idProfile}`, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    let sourceIds = detailProfile.data.doc.sourceIds || []
    let authorIds = detailProfile.data.doc.authorIds || []
    let contentIds = detailProfile.data.doc.contentIds || []
    let name = detailProfile.data.doc.name
    let description = detailProfile.data.doc.description

    contentIds.push(idContent)
    updateProfileFromContent.mutate({
      id: idProfile,
      newData: { sourceIds, authorIds, contentIds, name, description },
      token,
    })
  }
  const unSelectProfile = (e) => {
    const newArrayProfiles = arrayProfiles.filter((p) => p.value != e.value.value)
    setArrayProfiles(newArrayProfiles)
    const arrayProfilesFormat = newArrayProfiles.map((p) => p.value)
    removeContentFromProfile(data?.id, e?.value?.value)
    updateContentMutation.mutate({
      id: data.id,
      newData: {
        userHandle: data?.userHandle,
        tagIds: arrayTags.map((p) => p.value),
        profileIds: arrayProfilesFormat,
        editedTextContent: data?.editedTextContent,
        violationContent: data?.violationContent,
        violationEnactment: data?.violationEnactment,
        title: data?.title,
        meta: data?.meta,
      },
      token,
    })
  }
  const selectProfile = (e) => {
    if (!arrayProfiles.find((p) => p.value.trim() === e?.value?.value?.trim())) {
      const newArrayProfiles = [...arrayProfiles, e.value]

      setArrayProfiles(newArrayProfiles)
      const arrayProfilesFormat = newArrayProfiles.map((p) => p.value)

      addContentFromProfile(data?.id, e?.value?.value)
      updateContentMutation.mutate({
        id: data.id,
        newData: { profileIds: arrayProfilesFormat },
        token,
      })
    }
  }
  const updateEditContext = () => {
    setEdit(false)
    updateContentMutation.mutate({
      id: data.id,
      newData: { editedTextContent: desc },
      token,
    })
  }
  const updateViolationContent = () => {
    setEditViolationContent(false)
    updateContentMutation.mutate({
      id: data.id,
      newData: { violationContent: violationcontent },
      token,
    })
  }
  const updateViolationenactment = () => {
    setEditViolationenactment(false)
    updateContentMutation.mutate({
      id: data.id,
      newData: { violationEnactment: violationenactment },
      token,
    })
  }
  const updateViolationTimes = () => {
    setEditViolationTimes(false)
    updateContentMutation.mutate({
      id: data.id,
      newData: { violationTimes: violationTimes },
      token,
    })
  }
  const updateContentCategory = (value) => {
    updateContentMutation.mutate({
      id: data.id,
      newData: { category: value.code },
      token,
    })
    setContentCategory(value)
    console.log(value)
  }

  const updateEditTitle = () => {
    setEditTitle(false)
    updateContentMutation.mutate({
      id: data.id,
      newData: { title: title },
      token,
    })
  }

  useEffect(() => {
    if (data) {
      addHistory.mutate({
        newData: {
          screen: "Bài viết",
          description: `Xem hoặc update chi tiết bài viết id: ${data?.id} từ: {title: ${data?.title || ""
            },userHandle: ${data?.userHandle},editedTextContent : ${data?.editedTextContent} , profileids: [${data?.profilesInfo?.map((p) => p?.id).join(", ") || ""
            }], tagids: [${data?.tagsInfo?.map((p) => p?.id).join(", ") || ""}], violationContent: ${data?.violationContent
            }, topicTagids: [${data?.topicTagsInfo?.map((p) => p?.id).join(", ") || ""}], entityNameIds: [${data?.entityNames?.map((p) => p?.id).join(", ") || ""
            }, violationEnactment: ${data?.violationEnactment}}`,
        },
        token,
      })
    }
    highlightTextContent()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  // const nomalizeEntityText = () => {
  //   var contents = data?.entityText || data?.textContent || "";
  //   var contentIndex = 0;
  //   var offset = 0;
  //   var preText;
  //   var result = [];
  //   if (listTextHighlight && listTextHighlight.length > 0) {
  //     listTextHighlight.forEach((item) => {
  //       if (item?.start < preText?.start) {
  //         offset += contents[contentIndex].length;
  //         contentIndex++;
  //       }
  //       var content = contents[contentIndex];
  //       var start = item.start + offset;
  //       var end = item.end + offset;
  //       console.log(offset);
  //       //cập nhật item
  //       item.start = start;
  //       item.end = end;
  //       result.push(item);
  //       preText = item
  //     });
  //   }
  //   console.log(result);
  //   setListTextHighlight(result);
  // }

  const highlightTextContent =  () => {
    try {
      var contents = data?.entityText || data?.textContent || "";
      var contentIndex = 0;
      var offset = 0; // Số lượng ký tự đã thêm vào content
      var preText;
      if (listTextHighlight && listTextHighlight.length > 0) {
        listTextHighlight.forEach((item) => {
          if (item?.start < preText?.start) {
            contentIndex++;
            offset = 0;
          }
          var content = contents[contentIndex];
          var start = item.start + offset;
          var end = item.end + offset;
          var text = content.substring(start, end); // Lấy ra đoạn text cần highlight
          console.log(start, end, contentIndex, text, item.entityNames.entityGroup);
          // var highlightedText = `<span style="background-color: yellow">${text}</span>`;
          var highlightedText = `<span class="container-${item?.entityNames?.entityGroup}"><span class="value-${item?.entityNames?.entityGroup}">${text}</span><span class="label-${item?.entityNames?.entityGroup}">${item?.entityNames?.entityGroup?.substring(0, 3)}</span></span>`;
          offset += highlightedText.length - text.length;
          console.log(offset);
          contents[contentIndex] = content.substring(0, start) + highlightedText + content.substring(end);
          preText = item
        });
      }
      console.log(contents);
      setContentEntityName(contents);
    } catch (error) {
      console.log(error);
    }

  };


  // const highlightTextContent =  () => {
  //   try {
  //     var contents = data?.entityText || data?.textContent || "";
  //     var contentIndex = 0;
  //     var offset = 0; // Số lượng ký tự đã thêm vào content
  //     var preText;
  //     if (listTextHighlight && listTextHighlight.length > 0) {
  //       listTextHighlight.forEach((item) => {
  //         if (item?.start < preText?.start) {
  //           contentIndex++;
  //           offset = 0;
  //         }
  //         var content = contents[contentIndex];
  //         var start = item.start + offset;
  //         var end = item.end + offset;
  //         var text = content.substring(start, end); // Lấy ra đoạn text cần highlight
  //         console.log(start, end, contentIndex, text, item.entityNames.entityGroup);
  //         // var highlightedText = `<span style="background-color: yellow">${text}</span>`;
  //         var highlightedText = `<span class="container-${item?.entityNames?.entityGroup}"><span class="value-${item?.entityNames?.entityGroup}">${text}</span><span class="label-${item?.entityNames?.entityGroup}">${item?.entityNames?.entityGroup?.substring(0, 3)}</span></span>`;
  //         offset += highlightedText.length - text.length;
  //         console.log(offset);
  //         contents[contentIndex] = content.substring(0, start) + highlightedText + content.substring(end);
  //         preText = item
  //       });
  //     }
  //     console.log(contents);
  //     setContentEntityName(contents);
  //   } catch (error) {
  //     console.log(error);
  //   }

  // };


  const handleLabelEntity = (e) => {
    console.log(e.target.value);
  }

  const setEmpty = () => {
    setArrayProfiles([])
    setArrayTags([])
    setDesc("")
    setViolationcontent("")
    setViolationenactment("")
    setTitle("")
  }
  const acceptFunc = async () => {
    // deleteContent.mutate({
    //   id: data.id,
    //   token,
    // })
  }
  const rejectFunc = () => { }
  const confirm = (event) => {
    const myConfirm = confirmPopup({
      target: event.currentTarget,
      message: "Bạn xác nhận muốn bỏ qua bài viết?",
      icon: "pi pi-exclamation-triangle",
      accept: () => acceptFunc(),
      reject: () => rejectFunc(),
    })

    // setTimeout(() => {
    //   myConfirm.hide()

    //   setTimeout(() => {
    //     myConfirm.show()
    //   }, 1000)
    // }, 500)
  }

  const items = [
    { label: 'Phát hiện thực thể', },
    { label: 'Nguyên bản' },
    // {label: 'Calendar', icon: 'pi pi-fw pi-calendar'},
    // {label: 'Documentation', icon: 'pi pi-fw pi-file'},
    // {label: 'Settings', icon: 'pi pi-fw pi-cog'}
  ];


  const handleTextSelect = () => {
    const container = document.getElementById('entity-id');
    const selectedText = window.getSelection().toString();
    const range = window.getSelection().getRangeAt(0);
    const preSelectionRange = range.cloneRange();
    preSelectionRange.selectNodeContents(container);
    preSelectionRange.setEnd(range.startContainer, range.startOffset);
    const start = preSelectionRange.toString().length;

    setSelection({
      text: selectedText,
      start: start,
      end: start + selectedText.length,
    });
  };

  const handleHighlight = () => {
    console.log(data?.entityText);
    console.log(selection);
    listTextHighlight.push({
      start: selection.start,
      end: selection.end,
      entityNames: {
        entityGroup: "PER",
        name: "Nguyễn Văn A",
      },
    });
    console.log(listTextHighlight);
    highlightTextContent();

  }

  return (
    <>
      <TabMenu model={items} activeIndex={activeIndex} style={{ paddingBottom: '10px' }} onTabChange={(e) => setActiveIndex(e.index)} />
      {/* <span className="p-buttonset xl:w-5 h-3rem "> */}
      {/* <TabMenu model={items}/>

        <Button label="Save" icon="pi pi-check" />
        <Button label="Delete" icon="pi pi-trash" /> */}
      {/* </span> */}
      <div className="xl:flex-row xl:flex-nowrap flex flex-column justify-content-end gap-4 relative">
        <div className="xl:w-7 xl:pl-4 h-32rem xl:overflow-y-scroll" id="style-4">

          {/* <div className="flex align-items-center gap-2 mb-3">
            {data?.authorInfo?.name && (
              <>
                <Avatar image={data?.authorInfo?.avatar} className="mr-2" size="xlarge" shape="circle" />
                <div>
                  <div className="font-bold">{data?.authorInfo?.name}</div>
                </div>
              </>
            )}
          </div> */}
          {/* <div style={{ lineHeight: "1.5rem" }}>
            {data?.renderedContent && (activeIndex != 0) ? (
              <div key={'origin'} dangerouslySetInnerHTML={{ __html: data.renderedContent }} />
            ) : (
              <div key={'entity'} dangerouslySetInnerHTML={{ __html: contentEntityName }} />
            )}
          </div> */}
          <div id="entity-id" className="entity-container">
            <div onMouseUp={handleTextSelect} style={{ lineHeight: "1.5rem" }}>
              {data?.renderedContent && (activeIndex != 0) ? (
                <div key={'origin'} dangerouslySetInnerHTML={{ __html: data.renderedContent }} />
              ) : (
                <div key={'entity'} dangerouslySetInnerHTML={{ __html: contentEntityName }} />
              )}
            </div>
          </div>
          <HighlightMenu
            target=".entity-container"
            allowedPlacements={["top", "bottom"]}
            menu={({ selectedText = "", setClipboard, setMenuOpen, selectedHtml }) => (
              <>
                <Button icon="pi pi-user" className="p-button-info" label="PER" onClick={handleHighlight} />
                <Button icon="pi pi-globe" className="p-button-danger" label="LOC" onClick={handleHighlight} />
                <Button className="p-button-help" label="ORG" icon="pi pi-briefcase" />
                <Button className="p-button" label="MIS" icon="pi pi-briefcase" />
                {/* <MenuButton
                    title="Copy to clipboard"
                    icon="clipboard"
                    onClick={() =>
                      setClipboard(selectedText, () => {
                        alert("Copied to clipboard");
                      })
                    }
                  />

                  <MenuButton
                    title="Search Google"
                    onClick={() => {
                      window.open(
                        `https://www.google.com/search?q=${encodeURIComponent(
                          selectedText
                        )}`
                      );
                    }}
                    icon="magnifying-glass"
                  />
                  <MenuButton
                    title="Close menu"
                    onClick={() => setMenuOpen(false)}
                    icon="x-mark"
                  /> */}
              </>
            )}
          />
          {/* <p onMouseUp={handleTextSelect}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit.
            Integer nec odio. Praesent libero. Sed cursus ante dapibus diam.
            Sed nisi. Nulla quis sem at nibh elementum imperdiet. Duis sagittis ipsum.
          </p> */}
          <p>Selected Text: {selection.text}</p>
          <p>Start: {selection.start}, End: {selection.end}</p>


          <div className="flex flex-column align-items-center gap-2">
            {data?.imageContents && data?.imageContents?.length
              ? data?.imageContents?.map((image, i) => (
                <React.Fragment>
                  <img
                    className="w-full h-full"
                    src={image ? image : imageInvalid}
                    onError={(e) => (e.target.src = imageInvalid)}
                    alt=""
                  />
                </React.Fragment>
              ))
              : ""}
          </div>
          <div className="flex flex-column align-items-center gap-2">
            {data?.videoContents && data?.videoContents?.length
              ? data?.videoContents?.map((video, i) => (
                <React.Fragment>
                  <video controls className="w-full">
                    <source src={video} type="video/mp4" />
                  </video>
                </React.Fragment>
              ))
              : ""}
          </div>
          <hr className="my-2" />
          <div className="flex justify-content-between">
            <div className="flex" style={{ gap: "30px" }}>
              <div className="inline-flex align-items-center gap-1">
                <span className="mr-1 font-bold"> {data.views}</span>{" "}
                <i className="pi pi-eye" style={{ color: "blue" }}></i>
              </div>
              <div className="inline-flex align-items-center gap-1">
                <span className="mr-1 font-bold"> {data.likes}</span>{" "}
                <i className="pi pi-thumbs-up" style={{ color: "blue" }}></i>
              </div>
              <div className="inline-flex align-items-center gap-1">
                <span className="mr-1 font-bold"> {data.commentCount}</span>{" "}
                <i className="pi pi-comments" style={{ color: "#a89b32" }}></i>
              </div>
              <div className="inline-flex align-items-center gap-1">
                <span className="mr-1 font-bold"> {data.shares}</span>{" "}
                <i
                  className="pi pi-share-alt
"
                ></i>
              </div>
            </div>
          </div>
          <hr className="my-2" />
          {data?.topicsInfo && data?.topicsInfo.length ? (
            <div className="gap-2 flex flex-wrap">
              {data?.topicsInfo?.map((topic, i) => (
                <span
                  key={i}
                  onClick={() => {
                    history.push({
                      pathname: "/bai-viet",
                      search: `?topicId=${topic.id}`,
                    })
                    dispatch(clearQueryStr())
                  }}
                  className="product-badge status-new inline-block cursor-pointer"
                >
                  {topic.name}
                </span>
              ))}
            </div>
          ) : (
            ""
          )}
          <hr />
          <div className="flex flex-wrap" style={{ rowGap: "15px" }}>
            {data.commentInfos && data.commentInfos.length
              ? data.commentInfos.map((comment, index) => (
                <div className="flex" style={{ columnGap: "15px" }} key={index}>
                  <img className="w-4rem h-4rem border-circle" src={comment?.authorId?.avatar} alt="" />
                  <p className="inline-block">{comment?.textContent}</p>
                </div>
              ))
              : ""}
          </div>
        </div>
        <div className="xl:w-5 h-32rem overflow-y-scroll">
          <div className="mb-2 mr-2">
            <h5>Tiêu đề</h5>
            <div className="relative ">
              {editTitle ? (
                <i className="pi pi-check absolute top--5" onClick={() => updateEditTitle()}></i>
              ) : (
                <i className="pi pi-pencil absolute top--5" onClick={() => setEditTitle(true)}></i>
              )}
              {editTitle ? (
                <InputTextarea className="w-full" value={title} onChange={(e) => setTitle(e.target.value)} />
              ) : (
                <p className="border-400 border-round border-round-md border-1 pb-4 pt-2 px-2">{title}</p>
              )}
            </div>
          </div>
          <div className="mb-2  mr-2">
            <h5>Tóm tắt</h5>
            <div className="relative ">
              {edit ? (
                <i className="pi pi-check absolute top--5" onClick={() => updateEditContext()}></i>
              ) : (
                <i className="pi pi-pencil absolute top--5" onClick={() => setEdit(true)}></i>
              )}
              {edit ? (
                <InputTextarea autoResize className="w-full" value={desc} onChange={(e) => setDesc(e.target.value)} />
              ) : (
                <p className="border-400 border-round border-round-md border-1 pb-4 pt-2 px-2">{desc}</p>
              )}
            </div>
          </div>
          <div className="mb-2  mr-2">
            <h5>Nội dung vi phạm</h5>
            <div className="relative ">
              {editViolationContent ? (
                <i className="pi pi-check absolute top--5" onClick={() => updateViolationContent()}></i>
              ) : (
                <i className="pi pi-pencil absolute top--5" onClick={() => setEditViolationContent(true)}></i>
              )}

              {editViolationContent ? (
                <InputTextarea
                  autoResize
                  className="w-full"
                  value={violationcontent}
                  onChange={(e) => setViolationcontent(e.target.value)}
                />
              ) : (
                <p className="border-400 border-round border-round-md border-1 pb-4 pt-2 px-2">{violationcontent}</p>
              )}
            </div>
          </div>
          <div className="mb-2  mr-2">
            <h5>Điều khoản vi phạm</h5>
            <div className="relative ">
              {editViolationenactment ? (
                <i className="pi pi-check absolute top--5" onClick={() => updateViolationenactment()}></i>
              ) : (
                <i className="pi pi-pencil absolute top--5" onClick={() => setEditViolationenactment(true)}></i>
              )}

              {editViolationenactment ? (
                <InputTextarea
                  className="w-full"
                  value={violationenactment}
                  onChange={(e) => setViolationenactment(e.target.value)}
                />
              ) : (
                <p className="border-400 border-round border-round-md border-1 pb-4 pt-2 px-2">{violationenactment}</p>
              )}
            </div>
          </div>
          <div className="mb-2  mr-2">
            <h5>Danh mục bài viết</h5>
            <div className="relative ">
              <Dropdown
                value={contentCategory}
                onChange={(e) => updateContentCategory(e.value)}
                options={category}
                optionLabel="name"
                placeholder="Chọn danh mục"
                className="w-full "
              />
            </div>
          </div>
          {data.type === "YOUTUBE" ? (
            <div className="mb-2  mr-2">
              <h5>Khoảng thời gian vi phạm</h5>
              <div className="relative ">
                {editViolationTimes ? (
                  <i className="pi pi-check absolute top--5" onClick={() => updateViolationTimes()}></i>
                ) : (
                  <i className="pi pi-pencil absolute top--5" onClick={() => setEditViolationTimes(true)}></i>
                )}

                {editViolationTimes ? (
                  <InputTextarea
                    className="w-full"
                    value={violationTimes}
                    onChange={(e) => setViolationTimes(e.target.value)}
                  />
                ) : (
                  <p className="border-400 border-round border-round-md border-1 pb-4 pt-2 px-2">{violationTimes}</p>
                )}
              </div>
            </div>
          ) : (
            ""
          )}

          <div className="p-fluid mb-2  mr-2">
            <h5>Thẻ</h5>
            <AutoComplete
              className="w-full flex"
              dropdown
              multiple
              field="label"
              onKeyPress={(e) => enterNoExitEntityName(e)}
              suggestions={filterTags}
              onDropdownClick={() => setFilterTags([...filterTags])}
              completeMethod={searchTags}
              value={arrayTags}
              onUnselect={(e) => unSelectTag(e)}
              onSelect={(e) => selectTag(e)}
            />
          </div>
          <div className="p-fluid mb-2  mr-2">
            <h5>Chủ đề</h5>
            <AutoComplete
              className="w-full flex"
              dropdown
              multiple
              field="label"
              onKeyPress={(e) => enterNoExitTopicTag(e)}
              suggestions={filterTopicTags}
              onDropdownClick={() => setFilterTopicTags([...filterTopicTags])}
              completeMethod={searchTopicTags}
              value={arrayTopicTags}
              onUnselect={(e) => unSelectTopicTag(e)}
              onSelect={(e) => selectTopicTag(e)}
            />
          </div>
          <div className="p-fluid mb-2  mr-2">
            <h5>Tên thực thể</h5>
            <AutoComplete
              className="w-full flex"
              dropdown
              multiple
              field="label"
              onKeyPress={(e) => enterNoExit(e)}
              suggestions={filterEntityNames}
              onDropdownClick={() => setFilterEntityNames([...filterEntityNames])}
              completeMethod={searchEntityNames}
              value={arrayEntityNames}
              onUnselect={(e) => unSelectEntityName(e)}
              onSelect={(e) => selectEntityName(e)}
            />
          </div>
          <div className="p-fluid  mr-2">
            <h5>Hồ sơ vụ việc</h5>
            <AutoComplete
              className="w-full flex"
              dropdown
              multiple
              field="label"
              onKeyPress={(e) => enterNoExitProfiles(e)}
              suggestions={filterProfiles}
              onDropdownClick={() => setFilterProfiles([...filterProfiles])}
              completeMethod={searchProfiles}
              value={arrayProfiles}
              onUnselect={(e) => unSelectProfile(e)}
              onSelect={(e) => selectProfile(e)}
            />
          </div>
          {data?.screenShot && (
            <div className="flex flex-wrap justify-content-center mt-2">
              <h5 className="w-full">Ảnh chụp</h5>
              <Image src={data?.screenShot} alt="Image screenshot" width="250" className="" preview />
            </div>
          )}
        </div>
      </div>
      <div className="flex gap-1 mt-3 justify-content-end">
        <Button
          className="p-button-secondary w-auto"
          onClick={() => {
            closeDialog()
            setEmpty()
          }}
        >
          Đóng
        </Button>
        <Button onClick={confirm} className="w-auto" label="Bỏ qua"></Button>
        <ConfirmPopup />
        <Button
          className="p-button-primary w-auto"
          onClick={() => {
            handlePost(data?.userHandle === "handledPost" ? "cancelHandle" : "handle")
            closeDialog()
            setEmpty()
          }}
        >
          {data?.userHandle === "handledPost" ? "Ngưng xử lý" : "Xử lý"}
        </Button>
      </div>
    </>
  )
}

export default DetailPost
