import moment from "moment";
import { FilterMatchMode } from "primereact/api";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { MultiSelect } from "primereact/multiselect";
import { Toast } from "primereact/toast";
import React, { useEffect, useRef, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Link } from "react-router-dom";
import { getAllEntityNames, removeEntityName } from "../../service/entityNameAPI";
import { Image } from 'primereact/image';

import Form from "./Form";
import { FileUpload } from "primereact/fileupload";
import { createHistory } from "../../service/historyAPI";
import { useSelector } from "react-redux";
import { SpeedDial } from 'primereact/speeddial';
import { Tooltip } from 'primereact/tooltip';
import DetailModelAI from "../../components/ModelAIManager/DetailModelDialog";
import DataTrainingDialog from "../../components/ModelAIManager/DataTrainingDialog";
import { getHanoiImageUrl } from "../../service/modelAPI";

ModelManager.propTypes = {};

function ModelManager(props) {
  const [userDialog, setEntityNameDialog] = useState(false);
  const [selection, setSelection] = useState(null);
  const [deleteEntityNameDialog, setDeleteEntityNameDialog] = useState(false);
  const [entityName, setEntityName] = useState(null);
  const [displayDialog, setDisplayDialog] = useState(false);
  const [displayDataTrainingDialog, setDisplayDataTrainingDialog] = useState(false);
  const [edit, setEdit] = useState(false);
  const toast = useRef(null);
  const queryClient = useQueryClient();
  const token = useSelector((state) => state.user.token);
  const userId = useSelector((state) => state.user?.userData?.id || "");
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    limit: 10,
    page: 0,
    sortOrder: -1,
    sortField: "createdAt",
    filters: {
      categories: { value: null },
      name: { value: null },
    },
  });

  const openEditEntityName = (rowData) => {
    setEdit(true);
    setEntityNameDialog(true);
    setEntityName(rowData);
  };

  const items = [
    {
      label: 'Xem dữ liệu huấn luyện',
      icon: 'pi pi-database',
      command: () => {
        toast.current.show({ severity: 'info', summary: 'Add', detail: 'Xem dữ liệu huấn luyện' });
        setDisplayDataTrainingDialog(true);
      }
    },
    {
      label: 'Xem lịch sử huấn luyện',
      icon: 'pi pi-history',
      command: () => {
        toast.current.show({ severity: 'success', summary: 'Update', detail: 'Xem lịch sử huấn luyện' });
      }
    },
    {
      label: 'Thực hiện',
      icon: 'pi pi-chevron-right',
      command: () => {
        toast.current.show({ severity: 'error', summary: 'Delete', detail: 'Bắt đầu huấn luyện' });
      }
    },

  ];


  const RenderModelComponent = (data) => {
    return <div className="col-12 lg:col-6 xl:col-6">
      <div className="speeddial-tooltip-demo" style={{ position: 'relative', }}>
        <Tooltip target=".speeddial-tooltip-demo .speeddial-left .p-speeddial-action" position="left" />

        <SpeedDial
          buttonStyle={{ border: 0 }}
          className="speeddial-left"
          style={{ right: 10, top: 10 }}
          model={items}
          direction="down"
          transitionDelay={80}
          // direction="down-left" type="quarter-circle"
          showIcon="pi pi-bars" hideIcon="pi pi-times"
          buttonClassName="p-button-outlined"
        />
        <div className="card">
          <div className="flex justify-content-between mb-3">
            <div>
              <span className="block text-700 font-medium text-xl mb-2"> Mô hình phát hiện tin tức Hà Nội</span>
              <span className="font-regular text-500">Cập nhật lần cuối : {moment().format('DD/MM/YYYY')}</span>
            </div>
            {/* <div
            className="flex align-items-center justify-content-center bg-cyan-100 border-round"
            style={{ width: '2.5rem', height: '2.5rem' }}
          > */}
            {/* <i className="pi pi-bars text-blue-500 text-xl" /> */}
            {/* </div> */}
          </div>
          <div className="mb-1">
            <span className="text-700 font-medium mb-1"> Tên mô hình : </span>
            <span className="text-green-500">Hanoi Long Form</span>
          </div>
          {/* <div className="mb-1">
            <span className="text-700 font-medium mb-1"> Độ chính xác (mAP) : </span>
            <span className="text-green-500">50%</span>
          </div> */}
          <div className="mb-1">
            <span className="text-700 font-medium mb-1"> Độ chính xác (accuracy) : </span>
            <span className="text-green-500">100%</span>
          </div>
          {/* <div className="mb-1">
            <span className="text-700 font-medium mb-1"> Độ chính xác (precision) : </span>
            <span className="text-green-500">50%</span>
          </div>
          <div className="mb-1">
            <span className="text-700 font-medium mb-1"> Độ chính xác (recall) : </span>
            <span className="text-green-500">50%</span>
          </div> */}
          <div className="mb-1">
            <span className="text-700 font-medium mb-1"> Độ chính xác (F1 Score) : </span>
            <span className="text-green-500">100%</span>
          </div>
          <div className="mb-1">
            <span className="text-700 font-medium mb-1"> Average training loss: </span>
            <span className="text-green-500">0%</span>
          </div>
          <div style={{ display: "flex", justifyContent: 'center', flexDirection: "column", alignItems: 'center' }}>
            <Image src={getHanoiImageUrl} alt="Image" width="100%" preview />
            <span className="text-700 font-light mb-1 text-xm"> Biếu đồ Confusion Matrix của mô hình sau khi huấn luyện </span>
          </div>

          <Button label="Xem chi tiết" className="p-button-info mt-3"
            onClick={() => {
              setDisplayDialog(true)
            }}
          />

        </div>
      </div >
    </div>
  }




  return (
    <div className="grid">
      <DetailModelAI displayDialog={displayDialog} closeDialog={() => setDisplayDialog(false)} />
      <DataTrainingDialog displayDialog={displayDataTrainingDialog} closeDialog={() => setDisplayDataTrainingDialog(false)} /> 
      <Toast ref={toast} />
      <div className="col-12">
        <div className="col-12">
          {/* <div className="card">
          </div> */}
          <div className=" grid">
            <RenderModelComponent />

            <RenderModelComponent />

            <div className="col-12 lg:col-6 xl:col-6">

              <div className="card mb-0">
                <div className="flex justify-content-between mb-3">
                  <div>
                    <span className="block text-700 font-medium mb-3"> Mô hình phân loại tích cực tiêu cực</span>
                    <span className="font-regular text-500">Cập nhật lần cuối : {moment().format('DD/MM/YYYY')}</span>
                    <div className="text-900 font-medium text-xl">
                      {Number(0).toLocaleString('vi')}
                    </div>


                  </div>
                  <div
                    className="flex align-items-center justify-content-center bg-cyan-100 border-round"
                    style={{ width: '2.5rem', height: '2.5rem' }}
                  >
                    <i className="pi pi-tags text-blue-500 text-xl" />
                  </div>
                </div>
                <span className="text-green-500 font-medium">
                  {Number(0)} chủ đề{' '}
                </span>
                <span className="text-500">được cập nhật hôm nay</span>
              </div>
            </div>
            <div className="col-12 lg:col-6 xl:col-6">

              <div className="card mb-0">
                <div className="flex justify-content-between mb-3">
                  <div>
                    <span className="block text-700 font-medium mb-3"> Mô hình phát hiện thực thể</span>
                    <span className="font-regular text-500">Cập nhật lần cuối : {moment().format('DD/MM/YYYY')}</span>
                    <div className="text-900 font-medium text-xl">
                      {Number(0).toLocaleString('vi')}
                    </div>
                  </div>
                  <div
                    className="flex align-items-center justify-content-center bg-cyan-100 border-round"
                    style={{ width: '2.5rem', height: '2.5rem' }}
                  >
                    <i className="pi pi-tags text-blue-500 text-xl" />
                  </div>
                </div>
                <span className="text-green-500 font-medium">
                  {Number(0)} chủ đề{' '}
                </span>
                <span className="text-500">được cập nhật hôm nay</span>
              </div>
            </div>
          </div>
        </div>

      </div>
      {/* <Dialog
        visible={userDialog}
        dismissableMask
        style={{ width: "800px" }}
        header={`${edit ? "Cập nhật thẻ" : "Thêm thẻ"}`}
        modal
        className="p-fluid"
        onHide={() => {
          setEntityNameDialog(false);
          setEntityName({});
          setEdit(false);
        }}
      >
        <Form
          btnText={edit ? "Edit" : "Add"}
          data={entityName}
          toast={toast}
          closeDialog={() => {
            setEntityNameDialog(false);
            setEntityName({});
          }}
        />
      </Dialog>
      <Dialog visible={deleteEntityNameDialog} dismissableMask style={{ width: "450px" }} header="Xác nhận" modal footer={deleteEntityNameDialogFooter} onHide={hideDeleteEntityNameDialog}>
        <div className="confirmation-content">
          <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
          {entityName && (
            <span>
              Bạn có chắc muốn xóa <b>{entityName.name}</b>?
            </span>
          )}
        </div>
      </Dialog> */}
    </div>
  );
}

export default ModelManager;
