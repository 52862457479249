import { pdf } from "@react-pdf/renderer"
import axios from "axios"
import {
  AlignmentType,
  convertInchesToTwip,
  Document,
  ExternalHyperlink,
  HeadingLevel,
  ImageRun,
  LevelFormat,
  Packer,
  Paragraph,
  Table,
  TableCell,
  TableLayoutType,
  TableRow,
  TextRun,
  WidthType,
  VerticalAlign,
  HeightRule,
  PageBreak,
} from "docx"
import * as FileSaver from "file-saver"
import { saveAs } from "file-saver"
import html2canvas from 'html2canvas'
import moment from "moment"
import { Button } from "primereact/button"
import { React, useEffect, useRef, useState } from "react"
import ReactDOM from 'react-dom'
import { useMutation } from "react-query"
import { useSelector } from "react-redux"
import { Cell, Legend, Pie, PieChart } from 'recharts'
import * as XLSX from "xlsx"
import { createHistory } from "../../../../service/historyAPI"
import PDFfile from "./PDFfile"

const data = [
  { name: 'Mạng xã hội', value: 137 },
  { name: 'Báo chí, trang tin', value: 2098 },
];

const COLORS = ['#606968', '#9fa0a5'];
const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

const mostPost = [
  {
    title: "Chiều nay Hà Nội bầu chức danh Chủ tịch UBND thành phố",
    source: 'VTV News'
  },
  {
    title: "Dừng 5 tuyến xe buýt: Không thể đổ lỗi cho vuệc Nhà nước trợ giá chưa đủ",
    source: 'Dân trí'
  },
  {
    title: "Hà Nội: Khởi công cụm công nghiệp tại xã Võng Xuyên (Phúc Thọ)",
    source: 'VNExpress'
  },
  {
    title: "Hà Nội: Trảm nhiều công trình vi phảm xây dựng tại quận Cầu giấy",
    source: 'Công an nhân dân'
  },
  {
    title: 'Nhiều người ở Hà Nội nằm viện do cúm A, chuyên giá cảnh báo biến chứng nguy hiểm',
    source: 'VTV News'
  }
]

const mostPost2 = [
  {
    title: 'Chiều 22/7 bầu tân Chủ tịch UBND thành phố Hà Nội',
    source: 'VTC1 tin tức'
  },
  {
    title: 'Hà Nội lập chỉ giới đỏ đường vành đai 4',
    source: 'Lao động Thủ đô'
  },
  {
    title: 'Bệnh nhân cúm A tăng nhanh trên địa bàn Thành phố',
    source: 'Thông tấn xã Việt Nam'
  }
]

export default function ExportWeek({ queryDate }) {

  const chartRef = useRef(null);
  const [imageUrl, setImageUrl] = useState('');
  const [isLoadingButton, setIsLoadingButton] = useState(false);

  const convertToBase64 = async () => {
    const chartComponent = (
      <PieChart width={300} height={200}>
        <Pie
          data={data}
          cx='50%'
          cy='50%'
          labelLine={false}
          label={renderCustomizedLabel}
          outerRadius={70}
          fill="#8884d8"
          dataKey="value"
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
        <Legend layout="vertical" verticalAlign="middle" align="right" margin={{ left: 20 }} />
      </PieChart>
    )

    const container = document.createElement('div');
    ReactDOM.render(chartComponent, container);
    document.body.appendChild(container);

    // Delay the call to html2canvas
    setTimeout(async () => {
      const canvas = await html2canvas(container.firstChild);
      const base64Image = canvas.toDataURL('image/png');
      const imageUrl = `data:image/png;base64,${base64Image.split(',')[1]}`;
      setImageUrl(imageUrl);
      console.log(imageUrl);

      // Don't forget to clean up after yourself
      document.body.removeChild(container);
    }, 3000);
  };

  useEffect(() => {
    // setTimeout(() => {
    convertToBase64();
    // }, 3000);
  }, []);

  console.log("queryDate", queryDate)
  const token = useSelector((state) => state.user.token)
  const mergeDedupe = (arr) => {
    return [...new Set([].concat(...arr))]
  }
  const userId = useSelector((state) => state.user?.userData?.id || "")

  const addHistory = useMutation(createHistory, {
    onError: (e) => {
      console.log(e)
    },
  })
  let start = new Date(queryDate[0])
  console.log("start", start)
  let end = new Date(queryDate[1])
  start.setDate(start.getDate())
  start.setHours(6, 0, 0, 0)
  start = start.toISOString()
  end.setHours(6, 0, 0, 0)
  end = end.toISOString()
  const fetchData = async () => {
    let queryStr = `${process.env.REACT_APP_API_URL}/content?userHandle=handledPost&fromDate=${start}&toDate=${end}`
    const data = await axios.get(queryStr, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    return data.data?.docs
  }

  const centerBold = (text) => {
    return new Paragraph({
      children: [
        new TextRun({
          text,
          bold: true,
          size: 28,
        }),
      ],
      spacing: {
        after: 80,
      },
      alignment: AlignmentType.CENTER,
    })
  }
  const header2Bold = (text) => {
    return new Paragraph({
      children: [
        new TextRun({
          text: "       " + text,
          bold: true,
          size: 28,
        }),
      ],
      spacing: {
        after: 80,
      },
      heading: HeadingLevel.HEADING_2,
      alignment: AlignmentType.LEFT,
    })
  }
  const normalParagraph = (text) => {
    return new Paragraph({
      children: [
        new TextRun({
          text: "       " + text,
          size: 28,
        }),
      ],
      spacing: {
        after: 80,
      },
      alignment: AlignmentType.LEFT,
    })
  }
  const firstListYoutubeParagraph = (data) => {
    return new Paragraph({
      children: [
        new TextRun({
          text: `       + ${moment(data.postedAt).format("HH:mm DD/MM/YYYY")}, trang youtube `,
          size: 28,
        }),
        new ExternalHyperlink({
          children: [
            new TextRun({
              text: `${data.sourceInfo.name}`,
              style: "Hyperlink",
              size: 28,
            }),
          ],
          link: data.sourceInfo.link,
        }),
        new TextRun({
          text: ` đăng video có tiêu đề `,
          size: 28,
        }),
        new TextRun({
          text: `${data.title}`,
          size: 28,
          bold: true,
        }),
        new TextRun({
          text: data?.editedTextContent !== "" ? `  và nội dung ${data.editedTextContent}` : "",
          size: 28,
        }),
      ],
      spacing: {
        after: 80,
      },
      alignment: AlignmentType.LEFT,
    })
  }
  const firstListFacebookParagraph = (data) => {
    return new Paragraph({
      children: [
        new TextRun({
          text: `       + ${moment(data.postedAt).format("HH:mm DD/MM/YYYY")}, tài khoản facebook `,
          size: 28,
        }),
        data.sourceInfo.type !== "FB_GROUP"
          ? new ExternalHyperlink({
            children: [
              new TextRun({
                text: `${data.sourceInfo.name}`,
                style: "Hyperlink",
                size: 28,
              }),
            ],
            link: data.sourceInfo.link,
          })
          : new ExternalHyperlink({
            children: [
              new TextRun({
                text: `${data.authorInfo.name}`,
                style: "Hyperlink",
                size: 28,
              }),
            ],
            link: data.authorInfo.link,
          }),
        data.sourceInfo.type !== "FB_GROUP"
          ? new TextRun({
            text: data?.editedTextContent !== "" ? `  đăng tin ${data.editedTextContent}` : data.textContent,
            size: 28,
          })
          : (new TextRun({
            text: `đăng trong nhóm `,
          }),
            new ExternalHyperlink({
              children: [
                new TextRun({
                  text: `${data.sourceInfo.name}`,
                  style: "Hyperlink",
                  size: 28,
                }),
              ],
              link: data.sourceInfo.link,
            }),
            new TextRun({
              text: data?.editedTextContent !== "" ? ` với nội dung ${data.editedTextContent}` : data.textContent,
            })),
      ],
      spacing: {
        after: 80,
      },
      alignment: AlignmentType.LEFT,
    })
  }
  const firstListWebsiteParagraph = (data) => {
    return new Paragraph({
      children: [
        new TextRun({
          text: `       + ${moment(data.postedAt).format("HH:mm DD/MM/YYYY")}, website `,
          size: 28,
        }),
        new ExternalHyperlink({
          children: [
            new TextRun({
              text: `${data.sourceInfo.name}`,
              style: "Hyperlink",
              size: 28,
            }),
          ],
          link: data.sourceInfo.link,
        }),
        new TextRun({
          text: ` đăng bài viết có tiêu đề `,
          size: 28,
        }),
        new TextRun({
          text: `${data.title}`,
          size: 28,
          bold: true,
        }),
        new TextRun({
          text: data?.editedTextContent !== "" ? `  và nội dung ${data.editedTextContent}` : "",
          size: 28,
        }),
      ],
      spacing: {
        after: 80,
      },
      alignment: AlignmentType.LEFT,
    })
  }
  const lotLoParagraph = (data) => {
    return new Paragraph({
      children: [
        new TextRun({
          text: `       + ${moment(data.postedAt).format("HH:mm DD/MM/YYYY")}, nguồn tin `,
          size: 28,
        }),
        new ExternalHyperlink({
          children: [
            new TextRun({
              text: `${data.sourceInfo.name}`,
              style: "Hyperlink",
              size: 28,
            }),
          ],
          link: data.sourceInfo.link,
        }),
        new TextRun({
          text:
            data?.editedTextContent !== ""
              ? `Đưa tin có nội dung ${data.editedTextContent}`
              : `Đưa tin có nội dung ${data.textContent}`,
          size: 28,
        }),
      ],
      spacing: {
        after: 80,
      },
      alignment: AlignmentType.LEFT,
    })
  }
  const exportWord = async () => {
    try {
      const data = await fetchData()
      const arrayTags = data
        ?.filter((p) => p?.tagsInfo && p?.tagsInfo?.length)
        ?.map((p) => p?.tagsInfo?.map((x) => x?.name))
      const mergerTags = mergeDedupe(arrayTags)
      const arrayTagsDocx = mergerTags.map(
        (p) =>
          new Paragraph({
            text: `${p}`,
            numbering: {
              reference: "my-numbering",
              level: 0,
            },
            heading: HeadingLevel.HEADING_2,
            style: "stylePara",
          }),
      )
      const tableHeader = new TableRow({
        children: [
          new TableCell({
            children: [new Paragraph("STT")],
            width: {
              size: 3,
              type: WidthType.PERCENTAGE,
            },
          }),
          new TableCell({
            children: [new Paragraph("Ngày đăng")],
            width: {
              size: 5,
              type: WidthType.PERCENTAGE,
            },
          }),
          new TableCell({
            children: [new Paragraph("Kênh đăng")],
            width: {
              size: 10,
              type: WidthType.PERCENTAGE,
            },
          }),
          new TableCell({
            children: [new Paragraph("Đường dẫn")],
            width: {
              size: 15,
              type: WidthType.PERCENTAGE,
            },
          }),
          new TableCell({
            children: [new Paragraph("Nội dung")],
            width: {
              size: 67,
              type: WidthType.PERCENTAGE,
            },
          }),
        ],
      })
      const table = new Table({
        width: {
          size: 100,
          type: WidthType.PERCENTAGE,
        },
        // cantSplit: true,
        rows: [
          tableHeader,
          ...data.map(
            (p, index) =>
              new TableRow({
                children: [
                  new TableCell({
                    children: [new Paragraph((index + 1).toString())],
                    // width: {
                    //   size: 10,
                    //   type: WidthType.PERCENTAGE,
                    // },
                  }),
                  new TableCell({
                    children: [new Paragraph(moment(p.postedAt).format("DD/MM"))],
                    // width: {
                    //   size: 10,
                    //   type: WidthType.PERCENTAGE,
                    // },
                  }),
                  new TableCell({
                    children: [new Paragraph(p?.sourceInfo?.name || "")],
                    // width: {
                    //   size: 20,
                    //   type: WidthType.PERCENTAGE,
                    // },
                  }),
                  new TableCell({
                    children: [
                      new Paragraph({
                        children: [
                          new ExternalHyperlink({
                            children: [
                              new TextRun({
                                text: `${p?.link}`,
                                style: "Hyperlink",
                                size: 28,
                              }),
                            ],
                            link: p?.link,
                          }),
                        ],
                      }),
                    ],
                    // width: {
                    //   size: 20,
                    //   type: WidthType.PERCENTAGE,
                    // },
                  }),
                  new TableCell({
                    children: [
                      new Paragraph(
                        p?.editedTextContent !== "" ? p?.editedTextContent : p.textContent.substring(0, 200),
                      ),
                    ],
                    // width: {
                    //   size: 40,
                    //   type: WidthType.PERCENTAGE,
                    // },
                  }),
                ],
              }),
          ),
        ],
      })
      const doc = new Document({
        numbering: {
          config: [
            {
              reference: "my-numbering",
              levels: [
                {
                  level: 1,
                  format: LevelFormat.DECIMAL,
                  text: "%1.",
                  alignment: AlignmentType.LEFT,
                  style: {
                    paragraph: {
                      indent: { left: convertInchesToTwip(0.2), hanging: convertInchesToTwip(0.18) },
                    },
                  },
                },
                {
                  level: 3,
                  format: LevelFormat.LOWER_LETTER,
                  text: "%2)",
                  alignment: AlignmentType.LEFT,
                  style: {
                    paragraph: {
                      indent: { left: convertInchesToTwip(0.2), hanging: convertInchesToTwip(0.18) },
                    },
                  },
                },
              ],
            },
          ],
        },
        styles: {
          default: {
            heading1: {
              run: {
                size: 28,
                bold: true,
                color: "000000",
              },
              paragraph: {
                spacing: {
                  after: 120,
                },
              },
            },
            heading2: {
              run: {
                size: 26,
                bold: true,
              },
              paragraph: {
                spacing: {
                  before: 240,
                  after: 120,
                },
              },
            },
          },
          paragraphStyles: [
            {
              id: "stylePara",
              name: "Style Paragraph",
              run: {
                color: "000000",
                size: 26,
                bold: true,
              },
            },
            {
              id: "stylePara1",
              name: "Style Paragraph 1",
              run: {
                color: "000000",
                size: 26,
              },
            },
          ],
        },
        sections: [
          {
            children: [
              //   new Paragraph({
              //     children: [
              //       new TextRun({
              //         text: "BẢN TIN TỔNG HỢP",
              //         bold: true,
              //         allCaps: true,
              //         size: 30,
              //       }),
              //     ],
              //     heading: HeadingLevel.HEADING_1,
              //     alignment: AlignmentType.CENTER,
              //   }),
              //   new Paragraph({
              //     children: [
              //       new TextRun({
              //         text: `(Từ 6h ${moment(start).format("DD/M/YYYY")} đến 6h ngày ${moment(end).format("DD/M/YYYY")})`,
              //         bold: true,
              //         size: 28,
              //       }),
              //     ],
              //     alignment: AlignmentType.CENTER,
              //   }),
              //   new Paragraph({
              //     children: [
              //       new TextRun({
              //         text: `Qua nắm bắt tính hình trên không gian mạng trong khoảng thời gian từ 6h ngày ${moment(
              //           start,
              //         ).format("DD/M/YYYY")} đến 6h ngày ${moment(start).format(
              //           "DD/M/YYYY",
              //         )} có một số tin chính đáng chú ý sau:`,
              //         size: 28,
              //       }),
              //     ],
              //     font: "Calibri",
              //     alignment: AlignmentType.JUSTIFIED,
              //   }),
              //   new Paragraph({
              //     children: [
              //       new TextRun({
              //         text: ``,
              //         size: 28,
              //       }),
              //     ],
              //     font: "Calibri",
              //     alignment: AlignmentType.JUSTIFIED,
              //   }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: `Phụ lục bản tin 6h ngày ${moment(end).format("DD/M/YYYY")}`,
                    size: 28,
                  }),
                ],
                font: "Calibri",
                alignment: AlignmentType.JUSTIFIED,
              }),
              // ...arrayTagsDocx,
              // new Paragraph({
              //   text: "Tin liên quan đến QĐ",
              //   numbering: {
              //     reference: "my-numbering",
              //     level: 0,
              //   },
              //   heading: HeadingLevel.HEADING_2,
              //   style: "stylePara",
              // }),
              // new Paragraph({
              //   text: "Tin liên quan đến hoạt động tuyên truyền chống phá QĐ",
              //   numbering: {
              //     reference: "my-numbering",
              //     level: 1,
              //   },
              //   heading: HeadingLevel.HEADING_3,
              //   style: "stylePara1",
              // }),
              // new Paragraph({
              //   text: "Tin khác liên quan đến QĐ",
              //   numbering: {
              //     reference: "my-numbering",
              //     level: 1,
              //   },
              //   heading: HeadingLevel.HEADING_3,
              //   style: "stylePara1",
              // }),
              // new Paragraph({
              //   text: "Tin lộ lọt tài liệu",
              //   numbering: {
              //     reference: "my-numbering",
              //     level: 0,
              //   },
              //   heading: HeadingLevel.HEADING_2,
              //   style: "stylePara",
              // }),
              // new Paragraph({
              //   text: "Tin chú ý khác",
              //   numbering: {
              //     reference: "my-numbering",
              //     level: 0,
              //   },
              //   heading: HeadingLevel.HEADING_2,
              //   style: "stylePara",
              // }),
              // new Paragraph({
              //   text: "Yêu cầu xử lý",
              //   numbering: {
              //     reference: "my-numbering",
              //     level: 0,
              //   },
              //   heading: HeadingLevel.HEADING_2,
              //   style: "stylePara",
              // }),
              // new Paragraph({
              //   text: "(Bản tin chi tiết xem bản trên định dạng PDF) Người tổng hợp: XX",

              //   style: "stylePara1",
              // }),
              table,
            ],
          },
        ],
      })
      addHistory.mutate({ newData: { screen: "Export", description: `Export bản tin ngày - word` }, token })

      Packer.toBlob(doc).then((buffer) => {
        saveAs(buffer, "day.docx")
      })
    } catch (error) {
      console.log("Error when exportWord: ", error)
    }
  }
  const exportExcel = async () => {
    let queryStr = `${process.env.REACT_APP_API_URL}/content?userHandle=handledPost&fromDate=${start}&toDate=${end}`

    exportToCSV(queryStr, "day")
  }
  const exportToCSV = async (query, fileName) => {
    const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8"
    const fileExtension = ".xlsx"
    const csvData = await axios.get(`${query}`, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    console.log("csvData", csvData)
    const dataYoutube = csvData?.data?.docs?.filter((e) => e.type === "WEBSITE_POST") || []
    const dateExport = dataYoutube.map((p, index) => ({
      STT: (index + 1).toString(),
      "Tiêu đề": p.title,
      "Kênh đăng": p?.sourceInfo?.name,
      "Ngày đăng": moment(p.postedAt).format("HH:mm DD/MM/YYYY"),
      "Đường dẫn": p?.link,
      "Số người xem": p?.views || 0,
      "Số lượng người đăng ký kênh": p?.sourceInfo?.metaInfo?.subscribe || "",
      "Khoảng thời gian vi phạm": p?.violationTimes || "",
      "Nội dung vi phạm(chỉ rõ phút, giây vi phạm cái gì)": p?.violationContent,
      "Vi phạm điều khoản": p?.violationEnactment,
    }))
    var wscols = [
      { wch: 3 },
      { wch: 20 },
      { wch: 15 },
      { wch: 15 },
      { wch: 40 },
      { wch: 7 },
      { wch: 7 },
      { wch: 10 },
      { wch: 40 },
      { wch: 40 },
    ]
    const ws = XLSX.utils.json_to_sheet(dateExport)
    ws["!cols"] = wscols
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] }
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" })
    const data = new Blob([excelBuffer], { type: fileType })
    addHistory.mutate({ newData: { screen: "Export", description: `Export bản tin ngày - excel` }, token })
    FileSaver.saveAs(data, fileName + fileExtension)
  }
  const exportToPdf = async () => {
    const data = await fetchData()
    const arrayTags = data
      ?.filter((p) => p?.tagsInfo && p?.tagsInfo?.length)
      ?.map((p) => p?.tagsInfo?.map((x) => x?.name))
    const mergerTags = mergeDedupe(arrayTags)
    const formatData = data?.map((p, i) => ({
      stt: i,
      title: p.title,
      textcontent: p.textContent,
      postedat: moment(p.postedAt).format("DD/MM"),
      sourcename: p?.sourceInfo?.name,
      sourcelink: p?.sourceInfo?.link,
    }))
    addHistory.mutate({ newData: { screen: "Export", description: `Export bản tin ngày - pdf` }, token })
    const blob = await pdf(<PDFfile data={formatData} tags={mergerTags} queryDate={queryDate} />).toBlob()
    saveAs(blob, "day.pdf")
  }
  const exportSumary = async () => {
    try {
      setIsLoadingButton(true)

      const data = await fetchData()

      const data1 = data.filter((e) => e.category === "ChongPhaDangNhaNuoc")
      const data1f = data1.filter((e) => e.type === "FB_POST")
      const data1fg = data1f.filter((e) => e.sourceInfo.type === "FB_GROUP")
      const data1fp = data1f.filter((e) => e.sourceInfo.type !== "FB_GROUP")
      const data1w = data1.filter((e) => e.type === "WEBSITE_POST")
      const data1y = data1.filter((e) => e.type === "YOUTUBE")

      const data2 = data.filter((e) => e.category === "ChongPhaQuanDoi")
      const data2f = data2.filter((e) => e.type === "FB_POST")
      const data2fg = data2f.filter((e) => e.sourceInfo.type === "FB_GROUP")
      const data2fp = data2f.filter((e) => e.sourceInfo.type !== "FB_GROUP")
      const data2w = data2.filter((e) => e.type === "WEBSITE_POST")
      const data2y = data2.filter((e) => e.type === "YOUTUBE")

      const data3 = data.filter((e) => e.category === "KhacLienQuanQuanDoi")
      const data3f = data3.filter((e) => e.type === "FB_POST")
      const data3fg = data3f.filter((e) => e.sourceInfo.type === "FB_GROUP")
      const data3fp = data3f.filter((e) => e.sourceInfo.type !== "FB_GROUP")
      const data3w = data3.filter((e) => e.type === "WEBSITE_POST")
      const data3wqlt = data3w.filter((e) => e.sourceInfo.isQuality === true)
      const data3wnqlt = data3w.filter((e) => e.sourceInfo.isQuality !== true)
      const data3y = data3.filter((e) => e.type === "YOUTUBE")

      const data4 = data.filter((e) => e.category === "LotLoTaiLieu")
      const data4f = data4.filter((e) => e.type === "FB_POST")
      const data4fg = data4f.filter((e) => e.sourceInfo.type === "FB_GROUP")
      const data4fp = data4f.filter((e) => e.sourceInfo.type !== "FB_GROUP")
      const data4w = data4.filter((e) => e.type === "WEBSITE_POST")
      const data4y = data4.filter((e) => e.type === "YOUTUBE")

      const data5 = data.filter((e) => e.category === "TinKhac")
      const data5f = data5.filter((e) => e.type === "FB_POST")
      const data5fg = data5f.filter((e) => e.sourceInfo.type === "FB_GROUP")
      const data5fp = data5f.filter((e) => e.sourceInfo.type !== "FB_GROUP")
      const data5w = data5.filter((e) => e.type === "WEBSITE_POST")
      const data5wqlt = data5w.filter((e) => e.sourceInfo.isQuality === true)
      const data5wnqlt = data5w.filter((e) => e.sourceInfo.isQuality !== true)
      const data5y = data5.filter((e) => e.type === "YOUTUBE")
      const arrayTags = data
        ?.filter((p) => p?.tagsInfo && p?.tagsInfo?.length)
        ?.map((p) => p?.tagsInfo?.map((x) => x?.name))
      const mergerTags = mergeDedupe(arrayTags)
      const arrayTagsDocx = mergerTags.map(
        (p) =>
          new Paragraph({
            text: `${p}`,
            numbering: {
              reference: "my-numbering",
              level: 0,
            },
            heading: HeadingLevel.HEADING_2,
            style: "stylePara",
          }),
      )
      const tableHeader = new TableRow({
        children: [
          new TableCell({
            children: [new Paragraph("STT")],
            width: {
              size: 3,
              type: WidthType.PERCENTAGE,
            },
          }),
          new TableCell({
            children: [new Paragraph("Ngày đăng")],
            width: {
              size: 5,
              type: WidthType.PERCENTAGE,
            },
          }),
          new TableCell({
            children: [new Paragraph("Kênh đăng")],
            width: {
              size: 10,
              type: WidthType.PERCENTAGE,
            },
          }),
          new TableCell({
            children: [new Paragraph("Đường dẫn")],
            width: {
              size: 20,
              type: WidthType.PERCENTAGE,
            },
          }),
          new TableCell({
            children: [new Paragraph("Nội dung")],
            width: {
              size: 52,
              type: WidthType.PERCENTAGE,
            },
          }),
        ],
      })
      const table = new Table({
        width: {
          size: 3505,
          type: WidthType.PERCENTAGE,
        },
        // cantSplit: true,
        rows: [
          tableHeader,
          ...data.map(
            (p, index) =>
              new TableRow({
                children: [
                  new TableCell({
                    children: [new Paragraph((index + 1).toString())],
                    width: {
                      size: 3,
                      type: WidthType.PERCENTAGE,
                    },
                  }),
                  new TableCell({
                    children: [new Paragraph(moment(p.postedAt).format("DD/MM"))],
                    width: {
                      size: 5,
                      type: WidthType.PERCENTAGE,
                    },
                  }),
                  new TableCell({
                    children: [new Paragraph(p?.sourceInfo?.name || "")],
                    width: {
                      size: 10,
                      type: WidthType.PERCENTAGE,
                    },
                  }),
                  new TableCell({
                    children: [
                      new Paragraph({
                        children: [
                          new ExternalHyperlink({
                            children: [
                              new TextRun({
                                text: `${p?.link}`,
                                style: "Hyperlink",
                                size: 28,
                              }),
                            ],
                            link: p?.link,
                          }),
                        ],
                      }),
                    ],
                    width: {
                      size: 20,
                      type: WidthType.PERCENTAGE,
                    },
                  }),
                  new TableCell({
                    children: [new Paragraph(p?.textContent || "")],
                    width: {
                      size: 52,
                      type: WidthType.PERCENTAGE,
                    },
                  }),
                ],
              }),
          ),
        ],
      })
      // const doc = new Document({
      //   numbering: {
      //     config: [
      //       {
      //         reference: "my-numbering",
      //         levels: [
      //           {
      //             level: 1,
      //             format: LevelFormat.DECIMAL,
      //             text: "%1.",
      //             alignment: AlignmentType.LEFT,
      //             style: {
      //               paragraph: {
      //                 indent: {left: convertInchesToTwip(0.2), hanging: convertInchesToTwip(0.18)},
      //               },
      //             },
      //           },
      //           {
      //             level: 3,
      //             format: LevelFormat.LOWER_LETTER,
      //             text: "%2)",
      //             alignment: AlignmentType.LEFT,
      //             style: {
      //               paragraph: {
      //                 indent: {left: convertInchesToTwip(0.2), hanging: convertInchesToTwip(0.18)},
      //               },
      //             },
      //           },
      //         ],
      //       },
      //     ],
      //   },
      //   styles: {},
      //   sections: [
      //     {
      //       children: [
      //         new Paragraph({
      //           text: "BẢN TIN TỔNG HỢP 6H",
      //           bold: true,
      //           size: 30,
      //           heading: HeadingLevel.HEADING_1,
      //           alignment: AlignmentType.CENTER,
      //         }),
      //         centerBold(
      //           `(Từ 6h00 ${moment(start).format("DD/MM/YYYY")} đến 6h00 ngày ${moment(end).format("DD/MM/YYYY")})`,
      //         ),
      //         normalParagraph(
      //           `Qua nắm bắt tính hình trên không gian mạng trong khoảng thời gian từ 6h ngày ${moment(start).format(
      //             "DD/MM/YYYY",
      //           )} đến 6h ngày ${moment(start).format("DD/MM/YYYY")} có một số tin chính đáng chú ý sau:`,
      //         ),
      //         header2Bold("1. Tin liên quan đến hoạt động tuyên truyền, chống phá Đảng, Nhà nước"),
      //         ...data1y.map((e) => {
      //           return firstListYoutubeParagraph(e)
      //         }),
      //         ...data1f.map((e) => {
      //           return firstListFacebookParagraph(e)
      //         }),
      //         ...data1w.map((e) => {
      //           return firstListWebsiteParagraph(e)
      //         }),
      //         header2Bold("2. Tin liên quan đến Quân đội"),
      //         normalParagraph("a. Tin liên quan đến hoạt động tuyên truyền chống phá Quân đội"),
      //         data2y.length > 0
      //           ? normalParagraph(
      //               `Qua rà soát từ 6h ${moment(start).format("DD/MM/YYYY")} đến 6h00 ngày ${moment(end).format(
      //                 "DD/MM/YYYY",
      //               )} trên mạng xã hội youtube có một số nội dung đáng chú ý: `,
      //             )
      //           : normalParagraph(""),
      //         ...data2y.map((e) => {
      //           return normalParagraph(`+ ${e.title.toUpperCase()}`)
      //         }),
      //         data2f.length > 0
      //           ? normalParagraph(
      //               `Qua rà soát từ 6h ${moment(start).format("DD/MM/YYYY")} đến 6h00 ngày ${moment(end).format(
      //                 "DD/MM/YYYY",
      //               )} trên mạng xã hội facebook có một số nội dung đáng chú ý: `,
      //             )
      //           : normalParagraph(""),
      //         ...data2f.map((e) => {
      //           return normalParagraph(`+ ${e.editedTextContent !== "" ? e.editedTextContent : e.textContent}`)
      //         }),
      //         data2w.length > 0
      //           ? normalParagraph(
      //               `Qua rà soát từ 6h ${moment(start).format("DD/MM/YYYY")} đến 6h00 ngày ${moment(end).format(
      //                 "DD/MM/YYYY",
      //               )} trên các trang báo mạng có một số nội dung đáng chú ý: `,
      //             )
      //           : normalParagraph(""),
      //         ...data2w.map((e) => {
      //           return normalParagraph(`+ ${e.title.toUpperCase()}`)
      //         }),
      //         normalParagraph("b. Tin khác liên quan Quân đội"),
      //         data3wqlt.length > 0 ? normalParagraph("- Báo chính thống đưa tin:") : normalParagraph(""),
      //         ...data3wqlt.map((e) => {
      //           return firstListWebsiteParagraph(e)
      //         }),
      //         data3wnqlt.length > 0 ? normalParagraph("- Một số báo khác đưa tin:") : normalParagraph(""),
      //         ...data3wnqlt.map((e) => {
      //           return firstListWebsiteParagraph(e)
      //         }),
      //         data3f.length > 0 ? normalParagraph("- Trên mạng xã hội facebook:") : normalParagraph(""),
      //         ...data3f.map((e) => {
      //           return firstListFacebookParagraph(e)
      //         }),
      //         data3y.length > 0 ? normalParagraph("- Trên youtube:") : normalParagraph(""),
      //         ...data3y.map((e) => {
      //           return firstListYoutubeParagraph(e)
      //         }),
      //         header2Bold("3. Tin lộ lọt tài liệu"),
      //         data4.length > 0
      //           ? normalParagraph("- Một số nguồn đưa tin")
      //           : normalParagraph("- Chưa phát hiện tài liệu lộ liên quan đến quân đội"),
      //         ...data4.map((e) => {
      //           return lotLoParagraph(e)
      //         }),
      //         header2Bold("4. Tin chú ý khác"),
      //         data5wqlt.length > 0 ? normalParagraph("- Báo chính thống đưa tin:") : normalParagraph(""),
      //         ...data5wqlt.map((e) => {
      //           return firstListWebsiteParagraph(e)
      //         }),
      //         data5wqlt.length > 0 ? normalParagraph("- Một số tờ báo khác đưa tin:") : normalParagraph(""),
      //         ...data5wnqlt.map((e) => {
      //           return firstListWebsiteParagraph(e)
      //         }),
      //         data5f.length > 0 ? normalParagraph("- Trên mạng xã hội facebook:") : normalParagraph(""),
      //         ...data5f.map((e) => {
      //           return firstListFacebookParagraph(e)
      //         }),
      //         data5y.length > 0 ? normalParagraph("- Trên youtube:") : normalParagraph(""),
      //         ...data5y.map((e) => {
      //           return firstListYoutubeParagraph(e)
      //         }),
      //         header2Bold("5. Yêu cầu bóc gỡ"),
      //         normalParagraph(
      //           `Rà soát và đưa vào danh dách đề nghị bóc gỡ ${
      //             data1y.length + data2y.length + data3y.length + data4y.length + data5y.length
      //           } video xấu độc.`,
      //         ),
      //         normalParagraph(`(Bản tin chi tiết xem bản trên định dạng PDF) Người tổng hợp: `),
      //         centerBold(`Phụ lục bản tin 6h ngày ${moment(end).format("DD/MM/YYYY")})`),
      //         table,
      //       ],
      //     },
      //   ],
      // })

      const blob = await fetch(imageUrl).then(r => r.blob());
      const doc = new Document({
        numbering: {
          config: [
            {
              reference: 'my-numbering',
              levels: [
                {
                  level: 1,
                  format: LevelFormat.DECIMAL,
                  text: '%1.',
                  alignment: AlignmentType.LEFT,
                  style: {
                    paragraph: {
                      indent: { left: convertInchesToTwip(0.2), hanging: convertInchesToTwip(0.18) },
                    },
                  },
                },
                // {
                //   level: 3,
                //   format: LevelFormat.LOWER_LETTER,
                //   text: "%2)",
                //   alignment: AlignmentType.LEFT,
                //   style: {
                //     paragraph: {
                //       indent: { left: convertInchesToTwip(0.2), hanging: convertInchesToTwip(0.18) },
                //     },
                //   },
                // },
              ],
            },
          ],
        },
        styles: {
          default: {
            heading1: {
              run: {
                size: 28,
                bold: true,
                color: '000000',
              },
              paragraph: {
                spacing: {
                  after: 120,
                },
              },
            },
            heading2: {
              run: {
                size: 26,
                bold: true,
              },
              paragraph: {
                spacing: {
                  before: 240,
                  after: 120,
                },
              },
            },
          },
          paragraphStyles: [
            {
              id: 'stylePara',
              name: 'Style Paragraph',
              run: {
                color: '000000',
                size: 26,
                bold: true,
                underline: true,
              },
              paragraph: {
                spacing: {
                  before: 400,
                  after: 100,
                },
              },
            },
            {
              id: 'stylePara1',
              name: 'Style Paragraph 1',
              run: {
                color: '000000',
                size: 26,
              },
            },
          ],
        },
        sections: [
          {
            children: [
              new Table({
                width: { size: 100, type: WidthType.PERCENTAGE },
                layout: TableLayoutType.FIXED,
                rows: [
                  new TableRow({
                    children: [
                      new TableCell({
                        borders: {
                          left: { size: 1, color: 'auto', space: 0 },
                          top: { size: 1, color: 'auto', space: 0 },
                          bottom: { size: 1, color: 'auto', space: 0 },
                          right: { size: 1, color: 'auto', space: 0 },
                        },
                        children: [
                          new Paragraph({
                            alignment: AlignmentType.CENTER,
                            children: [
                              new TextRun({
                                text: 'UBND THÀNH PHỐ HÀ NỘI',
                                allCaps: true,
                                size: 26,
                                break: 0,
                              }),
                              new TextRun({
                                text: 'SỞ THÔNG TIN VÀ TRUYỀN THÔNG',
                                allCaps: true,
                                bold: true,
                                size: 26,
                                break: 1,
                              }),
                              // Tạo ra 1 đường kẻ ngang
                              new TextRun({
                                text: '──────────────────',
                                size: 26,
                                break: 1,
                              }),

                              // new TextRun({
                              //   text: '*',
                              //   allCaps: true,
                              //   size: 28,
                              //   break: 1,
                              // }),
                              // new TextRun({
                              //   text: 'Số        - BC/BTGTU',
                              //   allCaps: false,
                              //   size: 28,
                              //   break: 1,
                              // }),
                            ],
                          }),
                        ],
                        columnSpan: 8,
                      }),
                      new TableCell({
                        borders: {
                          right: { size: 1, color: 'auto', space: 0 },
                          top: { size: 1, color: 'auto', space: 0 },
                          bottom: { size: 1, color: 'auto', space: 0 },
                          left: { size: 1, color: 'auto', space: 0 },
                        },
                        children: [
                          new Paragraph({
                            alignment: AlignmentType.CENTER,
                            children: [
                              new TextRun({
                                text: 'CỘNG HÒA XÃ HỘI CHỦ NGHĨA VIỆT NAM',
                                allCaps: true,
                                size: 26,
                                underline: false,
                                bold: true,
                                break: 0,
                              }),
                              new TextRun({
                                text: 'Độc lập - Tự do - Hạnh phúc',
                                size: 26,
                                break: 1,
                                bold: true,
                              }),
                              new TextRun({
                                text: '──────────────────',
                                size: 26,
                                break: 1,
                              }),

                            ],
                          }),
                        ],
                        columnSpan: 8,
                      }),
                    ],
                  }),
                  new TableRow({
                    children: [
                      new TableCell({
                        borders: {
                          left: { size: 1, color: 'auto', space: 0 },
                          top: { size: 1, color: 'auto', space: 0 },
                          bottom: { size: 1, color: 'auto', space: 0 },
                          right: { size: 1, color: 'auto', space: 0 },
                        },
                        children: [
                          new Paragraph({
                            alignment: AlignmentType.CENTER,
                            children: [
                              new TextRun({
                                text: 'Số: 1800/ĐB-STTT',
                                size: 26,
                                break: 0,
                              }),
                            ],
                          }),
                        ],
                        columnSpan: 8,
                      }),
                      new TableCell({
                        borders: {
                          right: { size: 1, color: 'auto', space: 0 },
                          top: { size: 1, color: 'auto', space: 0 },
                          bottom: { size: 1, color: 'auto', space: 0 },
                          left: { size: 1, color: 'auto', space: 0 },
                        },
                        children: [
                          new Paragraph({
                            alignment: AlignmentType.CENTER,
                            children: [
                              new TextRun({
                                text: `Hà Nội, ngày ${moment(end).format('DD')} tháng ${moment(end).format('MM')} năm ${moment(end).format('YYYY')}`,
                                size: 26,
                                italics: true,
                                break: 0,
                              }),
                            ],
                          }),
                        ],
                        columnSpan: 8,
                      }),
                    ],
                  }),
                ],
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: 'Điểm thông tin',
                    bold: true,
                    allCaps: true,
                    size: 30,
                    break: 1,
                  }),
                ],
                alignment: AlignmentType.CENTER,
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: `Trên báo chí, trang tin, mạng xã hội về thành phố Hà Nội`,
                    size: 28,
                    italics: true,
                  }),
                ],
                alignment: AlignmentType.CENTER,
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: `(Từ ${moment(start).format("DD/MM/YYYY")} đến ${moment(end).format("DD/MM/YYYY")})`,
                    italics: true,
                    size: 28,
                  }),
                  new TextRun({
                    text: `──────────────────`,
                    italics: true,
                    size: 28,
                    break: 1,
                  }),
                ],
                alignment: AlignmentType.CENTER,
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: '',
                    size: 10, // 1 point is approximately 0.35mm, so 10px is approximately 10 points
                  }),
                ],
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: 'Phần I. Tổng quan thông tin',
                    bold: true,
                    underline: true,
                    size: 26,
                    break: 0,
                  }),
                ],
                alignment: AlignmentType.CENTER,
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: `1. Thống kê tổng quan`,
                    bold: true,
                    size: 26,
                    break: 1,
                  }),
                ],
                indent: { left: convertInchesToTwip(0.4), hanging: convertInchesToTwip(0.18) },
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: `Từ ngày ${moment(start).format("DD/MM")} đến ngày ${moment(end).format("DD/MM")}, có tổng cộng 2.345 tin, bài về thành phố Hà Nội, trong đó trên trang chí, trang tin có 2.123 tin, bài, trên mạng xã hội có 222 tin, bài.`,
                    size: 26,
                  }),
                ],
                indent: {
                  firstLine: convertInchesToTwip(0.4),
                }
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: ``,
                    bold: true,
                    size: 28,
                    break: 0,
                  }),
                ],
              }),
              new Table({
                rows: [
                  new TableRow({
                    children: [
                      new TableCell({
                        children: [
                          new Paragraph({
                            text: 'Tổng số bài đăng: 2.345',
                            size: 30,
                            alignment: AlignmentType.CENTER,
                          })
                        ],
                        rowSpan: 2,
                        verticalAlign: VerticalAlign.CENTER,
                        width: {
                          size: 15,
                          type: WidthType.PERCENTAGE,
                        },
                        margins: {
                          left: 100,
                          right: 100,
                        }
                      }),
                      new TableCell({
                        children: [
                          new Paragraph({
                            text: '* Báo chí, trang tin: 2.123',
                            size: 30,
                          }),
                          // new Paragraph({}), // Empty paragraph
                          // new Paragraph({}), // Empty paragraph
                        ],
                        verticalAlign: VerticalAlign.CENTER,
                        margins: {
                          left: 100,
                          right: 100,
                          top: 300,
                          bottom: 300,
                        }
                      }),
                      new TableCell({
                        children: [
                          new Paragraph({
                            text: 'Nguồn đăng',
                            size: 30,
                          }),
                          new Paragraph({
                            children: [
                              new ImageRun({
                                data: blob,
                                transformation: {
                                  width: 220,
                                  height: 150,
                                }
                              }),
                            ],
                          }),
                        ],
                        rowSpan: 2,
                      }),
                    ],
                  }),
                  new TableRow({
                    children: [
                      new TableCell({
                        children: [
                          new Paragraph({
                            text: '* Mạng xã hội: 222',
                            size: 30,
                          }),
                          new Paragraph({
                            text: '- Bình luận: 7223',
                            size: 30,
                            break: 1,

                          }),
                          new Paragraph({
                            text: '- Chia sẻ: 2202',
                            size: 30,
                            break: 1,
                          }),
                        ],
                        margins: {
                          left: 100,
                          right: 100,
                          top: 300,
                        }
                      }),
                    ],
                  }),
                ],
                width: {
                  size: 100,
                  type: WidthType.PERCENTAGE,
                },
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: `2. Các bài đăng trên báo chí, trang tin được lan tỏa nhiều nhất gồm:`,
                    bold: true,
                    size: 26,
                    break: 1,
                  }),
                ],
                indent: {
                  left: convertInchesToTwip(0.4),
                  hanging: convertInchesToTwip(0.18),
                }
              }),

              new Paragraph({
                children: [
                  new TextRun({
                    text: ``,
                    bold: true,
                    size: 28,
                    break: 0,
                  }),
                ],
              }),
              new Table({
                width: { size: 100, type: WidthType.PERCENTAGE },
                layout: TableLayoutType.FIXED,
                rows: [
                  new TableRow({
                    children: [
                      new TableCell({
                        children: [
                          new Paragraph({
                            text: 'STT',
                            bold: true,
                            size: 28,
                            alignment: AlignmentType.CENTER,

                          }),
                        ],
                        width: {
                          size: 3,
                          type: WidthType.PERCENTAGE,
                        },
                      }),
                      new TableCell({
                        children: [
                          new Paragraph({
                            text: 'Nội dung',
                            bold: true,
                            size: 28,
                            alignment: AlignmentType.CENTER,

                          }),
                        ],
                        width: {
                          size: 30,
                          type: WidthType.PERCENTAGE,
                        },
                        margins: {
                          top: 100,
                          bottom: 100,
                        }
                      }),
                      new TableCell({
                        children: [
                          new Paragraph({
                            text: 'Nguồn',
                            bold: true,
                            size: 28,
                            alignment: AlignmentType.CENTER,
                          }),
                        ],
                        width: {
                          size: 10,
                          type: WidthType.PERCENTAGE,
                        },
                        margins: {
                          top: 100,
                          bottom: 100,
                        }
                      }),
                    ],
                  }),
                  ...mostPost.map((e, index) => {
                    return new TableRow({
                      children: [
                        new TableCell({
                          children: [
                            new Paragraph({
                              text: `${index + 1}`,
                              size: 30,
                              alignment: AlignmentType.CENTER,
                            }),
                          ],
                          width: {
                            size: 3,
                            type: WidthType.PERCENTAGE,
                          },
                          verticalAlign: VerticalAlign.CENTER,
                        }),
                        new TableCell({
                          children: [
                            new Paragraph({
                              text: `${e.title}`,
                              size: 30,
                            }),
                          ],
                          width: {
                            size: 30,
                            type: WidthType.PERCENTAGE,
                          },
                          verticalAlign: VerticalAlign.CENTER,
                          margins: {
                            top: 100,
                            bottom: 100,
                            left: 100,
                          }
                        }),
                        new TableCell({
                          children: [
                            new Paragraph({
                              text: `${e.source}`,
                              size: 30,
                              alignment: AlignmentType.CENTER,
                            }),
                          ],
                          width: {
                            size: 10,
                            type: WidthType.PERCENTAGE,
                          },
                          verticalAlign: VerticalAlign.CENTER,
                          margins: {
                            top: 100,
                            bottom: 100,
                            left: 100,
                          }
                        }),
                      ],
                    })
                  }),
                ],
              }),
              new Paragraph({
                children: [
                  new PageBreak(),
                ],
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: `3. Các bài đăng trên các trang/nhóm mạng xã hội được dư luận quan tâm nhiều nhất gồm`,
                    bold: true,
                    size: 26,
                  }),
                ],
                indent: {
                  firstLine: convertInchesToTwip(0.4),
                }
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: ``,
                    bold: true,
                    size: 28,
                    break: 0,
                  }),
                ],
              }),
              new Table({
                width: { size: 100, type: WidthType.PERCENTAGE },
                layout: TableLayoutType.FIXED,
                rows: [
                  new TableRow({
                    children: [
                      new TableCell({
                        children: [
                          new Paragraph({
                            text: 'STT',
                            bold: true,
                            size: 30,
                            alignment: AlignmentType.CENTER,

                          }),
                        ],
                        width: {
                          size: 3,
                          type: WidthType.PERCENTAGE,
                        },
                      }),
                      new TableCell({
                        children: [
                          new Paragraph({
                            text: 'Nội dung',
                            bold: true,
                            size: 30,
                            alignment: AlignmentType.CENTER,

                          }),
                        ],
                        width: {
                          size: 30,
                          type: WidthType.PERCENTAGE,
                        },
                        margins: {
                          top: 100,
                          bottom: 100,
                        }
                      }),
                      new TableCell({
                        children: [
                          new Paragraph({
                            text: 'Fanpage/Nhóm',
                            bold: true,
                            size: 30,
                            alignment: AlignmentType.CENTER,
                          }),
                        ],
                        width: {
                          size: 10,
                          type: WidthType.PERCENTAGE,
                        },
                        margins: {
                          top: 100,
                          bottom: 100,
                        }
                      }),
                    ],
                  }),
                  ...mostPost2.map((e, index) => {
                    return new TableRow({
                      children: [
                        new TableCell({
                          children: [
                            new Paragraph({
                              text: `${index + 1}`,
                              size: 30,
                              alignment: AlignmentType.CENTER,
                            }),
                          ],
                          verticalAlign: VerticalAlign.CENTER,
                          width: {
                            size: 3,
                            type: WidthType.PERCENTAGE,
                          },
                        }),
                        new TableCell({
                          children: [
                            new Paragraph({
                              text: `${e.title}`,
                              size: 30,
                            }),
                          ],
                          width: {
                            size: 30,
                            type: WidthType.PERCENTAGE,
                          },
                          verticalAlign: VerticalAlign.CENTER,
                          margins: {
                            top: 100,
                            bottom: 100,
                            left: 100,
                          }
                        }),
                        new TableCell({
                          children: [
                            new Paragraph({
                              text: `${e.source}`,
                              size: 30,
                              alignment: AlignmentType.CENTER,
                            }),
                          ],
                          width: {
                            size: 10,
                            type: WidthType.PERCENTAGE,
                          },
                          verticalAlign: VerticalAlign.CENTER,
                          margins: {
                            top: 100,
                            bottom: 100,
                            left: 100,
                          }
                        }),
                      ],
                    })
                  }),
                ],
              }),

              new Paragraph({
                children: [
                  new TextRun({
                    text: 'Phần II. Nội dung thông tin',
                    bold: true,
                    underline: true,
                    size: 26,
                    break: 0,
                  }),
                ],
                alignment: AlignmentType.CENTER,
              }),

              new Paragraph({
                children: [
                  new TextRun({
                    text: `1. Thông tin tích cực`,
                    bold: true,
                    size: 26,
                    break: 1,
                  }),
                ],
                indent: { left: convertInchesToTwip(0.4), hanging: convertInchesToTwip(0.18) },
              }),

              new Paragraph({
                children: [
                  new TextRun({
                    text: `Báo Trung ương và Hà Nội đưa tin, chiều 22/7, HĐND Thành phố tổ chức kỳ
                    họp thứ tám, khóa XVI, nhiệm kỳ 2021-2026 (kỳ họp chuyên đề) để xem xét, bầu
                    chức danh Chủ tịch UBND Thành phố, theo Luật Tổ chức chính quyền địa phương
                    năm 2015, Luật sửa đổi bổ sung một số điều của Luật Tổ chức Chính phủ và Luật
                    Tổ chức chính quyền địa phương năm 2019 (Dân trí, Đại đoàn kết, Tiền phong, Hà
                    Nội mới).`,
                    size: 26,
                  }),
                ],
                indent: {
                  firstLine: convertInchesToTwip(0.4),
                }
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: `Ngày 21/7, Phó Chủ tịch UBND Thành phố Nguyễn Mạnh Quyền dự và phát
                    biểu chỉ đạo tại lễ khởi công Cụm công nghiệp Võng Xuyên (huyện Phúc Thọ)
                    (Chính phủ, Hà Nội mới, Kinh tế đô thị, An ninh Thủ đô).`,
                    size: 26,
                  }),
                ],
                indent: {
                  firstLine: convertInchesToTwip(0.4),
                }
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: `Một số thông tin tích cực khác được báo chí ghi nhận:`,
                    size: 26,
                  }),
                ],
                indent: {
                  firstLine: convertInchesToTwip(0.4),
                }
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: `+ Đoàn đại biểu Đảng ủy Khối các cơ quan Thành phố tổ chức dâng hương tưởng nhớ các Anh hùng liệt sĩ tại Nghĩa trang liệt sĩ Nhồn (quận Bắc Từ Liêm) (Hà Nội mới, Kinh tế & Đô thị).`,
                    size: 26,
                  }),
                ],
                indent: {
                  firstLine: convertInchesToTwip(0.4),
                }
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: `+ Đoàn đại biểu Quốc hội Thành phố thăm, tặng quả Bà mẹ Việt Nam anh hùng, thương binh, gia đình chính sách trên địa bàn quận Hà Đông và huyện Thanh Oai (Hà Nội mới)`,
                    size: 26,
                  }),
                ],
                indent: {
                  firstLine: convertInchesToTwip(0.4),
                }
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: `+ Sở Giáo dục và Đào tạo, Công đoàn ngành Giáo dục Hà Nội phối hợp Hội cựu giáo chức Thành phố tổ chức gặp mặt, tặng quà tri ân 100 nhà giáo là thương binh, thân nhân liệt sĩ (Hà Nội mới, Kinh tế & Đô thị)`,
                    size: 26,
                  }),
                ],
                indent: {
                  firstLine: convertInchesToTwip(0.4),
                }
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: `+ Chiều 21/7, Thành đoàn, Hội Sinh viên Thành phố tổ chức Lễ xuất quân “Hành trình trí ân - Sinh viên với biển, đảo Tổ quốc” năm 2022 nhằm tri ân những người con ưu tú của dân tộc đã dũng cảm chiến đấu, anh dũng hy sinh vì sự nghiệp đầu tranh giải phóng dân tộc, thống nhất đất nước, xây dựng và bảo vệ Tổ quốc (Nhân dân, Hà Nội mới).`,
                    size: 26,
                  }),
                ],
                indent: {
                  firstLine: convertInchesToTwip(0.4),
                }
              }),

              new Paragraph({
                children: [
                  new TextRun({
                    text: `2. Thông tin cần lưu ý`,
                    bold: true,
                    size: 26,
                    break: 1,
                  }),
                ],
                indent: { left: convertInchesToTwip(0.4), hanging: convertInchesToTwip(0.18) },
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: `2.1 Thông tin liên quan đến quy hoạch, xây dựng, giao thông`,
                    bold: true,
                    size: 26,
                  }),
                ],
                indent: {
                  firstLine: convertInchesToTwip(0.4),
                },
                // spacing: {
                //   line: 360,
                // }
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: `- Thành phố lập chỉ giới đổ đường vành đai 4`,
                    bold: true,
                    size: 26,
                  }),
                ],
                indent: {
                  firstLine: convertInchesToTwip(0.4),
                },
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: `Văn phòng UBND Thành phố có văn bản thông báo kết luận của Phó Chủ tịch 'UUBND Thành phố Dương Đức Tuần về việc xem xét, chỉ đạo triển khai công tác lập chỉ giới đường đỏ tuyến đường Vành đai 4 trên địa bàn Thành phố. Theo đó, việc triền khai tổ chức lập hồ sơ chỉ giới đường đỏ tuyến đường Vành đai 4 trên địa bàn 'Thành phố chia làm 4 đoạn. Đoạn 1 từ cầu Hồng Hà đến quốc lộ 18 (dài khoảng 11km); Đoạn 2 từ cầu Hồng Hà đến quốc lộ 32 (dài khoảng 9,6km); Đoạn 3 từ quốc lộ 32 đến quốc lộ 6 (dài khoảng 17,77km); Đoạn 4 từ quốc lộ 6 đến cầu Mễ Sở có tổng chiều dài khoảng 19,5km (Nhân dân, VOV!, Vietnamnet, Tiền phong, Lao động, Vnexpress)
                    `,
                    size: 26,
                  }),
                ],
                indent: {
                  firstLine: convertInchesToTwip(0.4),
                }
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: `- Ý kiến chuyên gia xung quanh việc Công ty TNHH Bắc Hà đề nghị dùng khai thác hợp đồng thầu đối với 5 tuyển buýt có trợ giá`,
                    bold: true,
                    size: 26,
                  }),
                ],
                indent: {
                  firstLine: convertInchesToTwip(0.4),
                },
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: `Xung quanh việc Công ty TNHH Bắc Hà đề nghị dừng khai thác hợp đồng thầu đối với 5 tuyến buýt có trợ giá (tuyến số 41, 42, 43, 44, 45), báo điện tử Chín phi có bài: “Đừng 5 tuyển xe buýt: Không thể đồ lỗi cho việc Nhà nước trợ giá chưa đủ” dẫn ý kiến phát biểu của TS. Thạch Minh Quân (giảng viên Trường Đại học Giao thông vận tải), cho rằng: “Các ryển vận tải hành khách công cộng của các doanh nghiệp được trợ giá không thể bị lỗ khi khai thác vận hành”. Theo ông Quân, để xác định chỉ phí của doanh nghiệp hoạt động trong lĩnh vực vận tải hành khách công cộng bằng xe buýt tại Hà Nội với các tuyến có trợ giá, được xác định thông qua các bộ định mức kinh tế kỹ thuật ban hành kèm theo quyết định của UBND Thành phố. Do đó không thể đồ lỗi cho việc Nhà nước trợ giá chưa đủ dẫn đền doanh nghiệp bị lỗ khi khai thác vận hành.`,
                    size: 26,
                  }),
                ],
                indent: {
                  firstLine: convertInchesToTwip(0.4),
                }
              }),

              new Paragraph({
                children: [
                  new TextRun({
                    text: `2.2 Thông tin khác`,
                    bold: true,
                    size: 26,
                  }),
                ],
                indent: { left: convertInchesToTwip(0.4), hanging: convertInchesToTwip(0.18) },
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: `- Bệnh nhân cúm A tăng nhanh trên địa bàn Thành phố`,
                    bold: true,
                    size: 26,
                  }),
                ],
                indent: {
                  firstLine: convertInchesToTwip(0.4),
                },
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: `Báo chí phản ánh thời gian gần đây, nhiều bệnh viện ở Hà Nội ghi nhận các trường hợp nhập viện và điều trị liên quan đến cúm A tăng nhanh. Nhiều chuyên gia y tế khuyến cáo người dân nên tiêm vaccine cúm, giúp giảm tỷ lệ mắc cúm hoặc nếu mắc thì giảm tỉ lệ nhập viện và các biến chứng nặng như viêm phối, viêm màng não (VTC3, Tin tức, Vietnamplus).`,
                    size: 26,
                  }),
                ],
                indent: {
                  firstLine: convertInchesToTwip(0.4),
                }
              }),

              new Paragraph({
                children: [
                  new TextRun({
                    text: `3. Thông tin các cơ quan Thành phố phản hồi`,
                    bold: true,
                    size: 26,
                  }),
                ],
                indent: { left: convertInchesToTwip(0.4), hanging: convertInchesToTwip(0.18) },
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: `Từ ngày 15/7 đến 22/7, Sở Thông tin và Truyền thông nhận được 04 văn bản của 03 cơ quan, đơn vị Thành phố cung cấp thông tin và phản hồi báo chí về những vấn đề sau:`,
                    size: 26,
                  }),
                ],
                indent: {
                  firstLine: convertInchesToTwip(0.4),
                },
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: `- Sở Công thương có văn bản gửi UBND huyện Thường Tín đề nghị xác minh làm rõ thông tin về việc chủ đầu tư Cụm công nghiệp Thắng Lợi (huyện Thường Tín) thi công khi chưa hoàn thiện đầy đủ thủ tục pháp lý theo phản ánh của báo tài nguyên & Môi trường.`,
                    size: 26,
                  }),
                ],
                indent: {
                  firstLine: convertInchesToTwip(0.4),
                }
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: `- UBND quận Đống Đa thông tin về kết quả kiểm tra, xử lý về việc Công ty TNHH TM Dịch vụ khai thác ô tô Ngọc Quang sử dụng vỉa hè phố Hào Nam (Đống Đa) thu tiền, lắp dựng rào chắn không đảm bảo 1,5 m cho người đi bộ theo phản ánh của báo Sức khỏe và Đời sống.`,
                    size: 26,
                  }),
                ],
                indent: {
                  firstLine: convertInchesToTwip(0.4),
                }
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: `- UBND quận Nam Từ Liêm thông tin về kết quả kiểm tra, xử lý vi phạm liên quan đến việc sử dụng trái phép đất nông nghiệp trên địa bàn Quận và tình trạng mất an toàn giao thông tại Dự án nâng cấp, mở rộng đường 70 đoạn chạy qua địa bàn phường Xuân Phương, Đại Mỗ theo phản ánh của báo: /ao động, Đại đoàn kết.`,
                    size: 26,
                  }),
                ],
                indent: {
                  firstLine: convertInchesToTwip(0.4),
                }
              }),

              new Paragraph({
                children: [
                  new TextRun({
                    text: `4. Thông tin cần lưu ý và trả lời báo chí theo quy định`,
                    bold: true,
                    size: 26,
                  }),
                ],
                indent: { left: convertInchesToTwip(0.4), hanging: convertInchesToTwip(0.18) },
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: `- Tái phát tình trạng cà phê đường tàu`,
                    bold: true,
                    size: 26,
                  }),
                ],
                indent: {
                  firstLine: convertInchesToTwip(0.4),
                },
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: `Sở Công thương có văn bản gửi UBND huyện Thường Tín đề nghị xác minh làm rõ thông tin về việc chủ đầu tư Cụm công nghiệp Thắng Lợi (huyện Thường Tín) thi công khi chưa hoàn thiện đầy đủ thủ tục pháp lý theo phản ánh của báo tài nguyên & Môi trường.`,
                    size: 26,
                  }),
                ],
                indent: {
                  firstLine: convertInchesToTwip(0.4),
                }
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: `Báo Tiền phong! tiếp tục có bài phản ánh về việc phố cà phê đường tàu, (đoạn đường sắt nối giữa phố Lê Duẩn, Trần Phú, Cửa Đông và Phùng Hưng) nhộn nhịp trở lại, bất chấp nguy hiểm, khách du lịch vô tư tụ tập, tạo dáng trên đường ray...`,
                    size: 26,
                  }),
                ],
                indent: {
                  firstLine: convertInchesToTwip(0.4),
                }
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: `Trên đây là Báo cáo tình hình thông tin trên báo chí, trang thông tin điện tử, mạng xã hội từ ngày ${moment(start).format("DD/MM/YYYY")} đến ngày ${moment(end).format("DD/MM/YYYY")}.`,
                    size: 26,
                  }),
                ],
                indent: {
                  firstLine: convertInchesToTwip(0.4),
                }
              }),

              new Paragraph({
                children: [
                  new TextRun({
                    text: ``,
                    bold: true,

                    break: 1,
                  }),
                ],
              }),

              new Table({
                width: { size: 100, type: WidthType.PERCENTAGE },
                layout: TableLayoutType.FIXED,
                rows: [
                  new TableRow({
                    children: [
                      new TableCell({
                        borders: {
                          left: { size: 1, color: 'auto', space: 0 },
                          top: { size: 1, color: 'auto', space: 0 },
                          bottom: { size: 1, color: 'auto', space: 0 },
                          right: { size: 1, color: 'auto', space: 0 },
                        },
                        children: [
                          new Paragraph({
                            alignment: AlignmentType.LEFT,
                            children: [
                              new TextRun({
                                text: 'Nơi nhận:',
                                size: 20,
                                bold: true,
                                break: 0,
                              }),
                              new TextRun({
                                text: '- Đ/c Bí thư Thành ủy;',
                                size: 20,
                                break: 1,
                              }),
                              new TextRun({
                                text: '- Thường trực Thành ủy;',
                                size: 20,
                                break: 1,
                              }),
                              new TextRun({
                                text: '- Thường trực HĐND Thành phố;',
                                size: 20,
                                break: 1,
                              }),
                              new TextRun({
                                text: '- Các PCT UBND Thành phố;',
                                size: 20,
                                break: 1,
                              }),
                              new TextRun({
                                text: '- Các đ/c trong Thường vụ Thành ủy;',
                                size: 20,
                                break: 1,
                              }),
                              new TextRun({
                                text: '- Ban Tuyên Giáo Thành ủy;',
                                size: 20,
                                break: 1,
                              }),
                              new TextRun({
                                text: '- Đảng ủy khối các cơ quan TP;',
                                size: 20,
                                break: 1,
                              }),
                              new TextRun({
                                text: '- Thủ tướng các Sở, ban, ngành;',
                                size: 20,
                                break: 1,
                              }),
                              new TextRun({
                                text: '- Chủ tịch UBND quận, huyện, thị xã;',
                                size: 20,
                                break: 1,
                              }),
                              new TextRun({
                                text: '- Người phát ngôn của các cơ quan hành chính nhà nước thuộc Thành phố',
                                size: 20,
                                break: 1,
                              }),
                              new TextRun({
                                text: '- PGĐ phụ trách Nguyễn Việt Hùng;',
                                size: 20,
                                break: 1,
                              }),
                              new TextRun({
                                text: '- PGĐ Sở Nguyễn Thị Mai Hương',
                                size: 20,
                                break: 1,
                              }),
                              new TextRun({
                                text: '- Phòng BCXBTT',
                                size: 20,
                                break: 1,
                              }),
                              new TextRun({
                                text: '- Lưu: VT, BC, TB',
                                size: 20,
                                break: 1,
                              }),
                            ],
                          }),
                        ],
                        columnSpan: 8,
                      }),
                      new TableCell({
                        borders: {
                          left: { size: 1, color: 'auto', space: 0 },
                          top: { size: 1, color: 'auto', space: 0 },
                          bottom: { size: 1, color: 'auto', space: 0 },
                          right: { size: 1, color: 'auto', space: 0 },
                        },
                        children: [
                          new Paragraph({
                            alignment: AlignmentType.CENTER,
                            children: [
                              new TextRun({
                                text: 'KT. GIÁM ĐỐC',
                                allCaps: true,
                                bold: true,
                                size: 28,
                                break: 0,
                              }),
                              new TextRun({
                                text: 'PHÓ GIÁM ĐỐC PHỤ TRÁCH',
                                allCaps: true,
                                bold: true,
                                size: 28,
                                break: 1,
                              }),
                              new TextRun({
                                text: 'Nguyễn Việt Hùng',
                                allCaps: true,
                                bold: true,
                                size: 28,
                                break: 3,
                              }),
                            ],
                          }),
                        ],
                        columnSpan: 8,
                      }),
                    ],
                  }),
                ],
              }),
            ],
          },
        ],
      })
      addHistory.mutate({ newData: { screen: "Export", description: `Export bản tin ngày - word` }, token })

      setTimeout(() => {
        Packer.toBlob(doc).then((buffer) => {
          // saveAs(buffer, "BAN_TIN_6h_.docx")
          setIsLoadingButton(false)
          saveAs(buffer, "BAN_TIN.docx")
        })
      }, 1000)

    } catch (error) {
      console.log("Error when exportWord: ", error)
    }
  }
  return (
    <div className="mb-4" style={{ zIndex: 1, background: 'white' }}>
      {/* <Button icon="pi pi-file" label="Bản tin 6h" onClick={exportSumary}></Button> */}
      <Button loading={isLoadingButton} icon="pi pi-file" label="Xuất" onClick={exportSumary}></Button>
    </div>
    // <div className="card">
    //   {/* <div className="mb-4">
    //     <Button icon="pi pi-file" label="Phụ lục doc" onClick={exportWord}></Button>
    //   </div>
    //   <div className="mb-4">
    //     <Button icon="pi pi-file-excel" label="Phụ lục video youtube" onClick={exportExcel}></Button>
    //   </div> */}
    // </div>
  )
}
