import React, { useState } from "react";
import { Button } from "primereact/button";
import { Inplace, InplaceDisplay, InplaceContent } from 'primereact/inplace';
import { Tag } from 'primereact/tag';

const ContentItem = ({ data, handleChangeTag, handleDeleteData }) => {
    const [active, setActive] = useState(false);

    return (
        <div className="col-12 md:col-4">
            <div className="product-grid-item card m-2">
                <div className="product-grid-item-top mb-3">
                    <div>
                        <i className="pi pi-tag product-category-icon"></i>
                        {/* <span className="product-category">{data.category}</span> */}
                    </div>
                    {
                        data?.isHanoi ? <div className="cursor-pointer" onClick={() => handleChangeTag(data)}> <Tag severity="success" value="Ha Noi"></Tag> </div> : <div className="cursor-pointer" onClick={() => handleChangeTag(data)}>  <Tag severity="warning" value="Not Ha Noi"></Tag> </div>
                    }
                </div>
                <div className="product-grid-item-content">
                    <Inplace active={active} onToggle={(e) => setActive(e.value)}>
                        <InplaceDisplay >
                            <span style={{ transition: 'none', padding: 0 }} className="transition-none">
                                {data?.textContent?.substr(0, 500) + `${data?.textContent?.length > 500 ? "... " : ""}`}
                            </span>
                        </InplaceDisplay>
                        <InplaceContent>
                            <div onClick={() => setActive(false)} style={{ padding: 0, cursor: 'pointer' }} className="p-2">
                                {data?.textContent}
                            </div>
                        </InplaceContent>
                    </Inplace>
                </div>
                <div className="product-grid-item-bottom mt-3">
                    <Button onClick={() => handleDeleteData(data)} icon="pi pi-trash" label="Loại bỏ" ></Button>
                </div>
            </div>
        </div>
    );
};

export default ContentItem;
