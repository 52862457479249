import axios from 'axios'

export const startTrainningModel = async ({query, token}) => {
  // &createdAt_gte=${}&createdAt__lte=${}
  try {
    const res = await axios.get(`http://14.224.131.219:4505/train`)
    if (res.data && res.data?.code && res?.data?.code != 200) {
      throw new Error(res?.data?.message || 'Đã có lỗi')
    }
    return res.data
  } catch (error) {}
}
export const getHanoiImageUrl = `${process.env.REACT_APP_API_URL}/modelAI/hanoi-loss-plot-image`