import axios from "axios";
export const handleError = (err) => {
    if (err.response.data.msg) {
        throw new Error(err.response.data.msg);
    } else {
        throw new Error(err.message);
    }
};

export const getAllContentTrainingData = async ({ params, token }) => {
    // &createdAt_gte=${}&createdAt__lte=${}
    // &createdAt_gte=${}&createdAt__lte=${}
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/contentClassificationHaNoiTrainingData`, {
            headers: {
                Authorization: "Bearer " + token,
            },
            params,
        });
        if (res.data && res.data?.code && res?.data?.code != 200) {
            throw new Error(res?.data?.message || "Đã có lỗi");
        }
        return res.data;
    } catch (error) { }
};

export const addContentTrainingData = async ({ newData, token }) => {
    const res = await axios.post(`${process.env.REACT_APP_API_URL}/contentClassificationHaNoiTrainingData`, newData, {
        headers: {
            Authorization: "Bearer " + token,
        },
    });
    if (res.data && res.data?.code && res?.data?.code != 200) {
        throw new Error(res?.data?.message || "Đã có lỗi");
    }
    return res.data;
};

export const updateContentTrainingData = async ({ id, newData, token }) => {
    const res = await axios.put(`${process.env.REACT_APP_API_URL}/contentClassificationHaNoiTrainingData/${id}`, newData, {
        headers: {
            Authorization: "Bearer " + token,
        },
    });
    if (res.data && res.data?.code && res?.data?.code != 200) {
        throw new Error(res?.data?.message || "Đã có lỗi");
    }
    return res.data;
}

export const deleteContentTrainingData = async ({ id, token }) => {
    const res = await axios.delete(`${process.env.REACT_APP_API_URL}/contentClassificationHaNoiTrainingData/${id}`, {
        headers: {
            Authorization: "Bearer " + token,
        },
    });
    console.log(res);
    if (res.data && res.data?.code && res?.data?.code != 200) {
        throw new Error(res?.data?.message || "Đã có lỗi");
    }
    return res.data;
};
