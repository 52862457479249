import React, { useRef, useState } from "react"
import PropTypes from "prop-types"
import { Dialog } from "primereact/dialog"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { Avatar } from "primereact/avatar"
import moment from "moment"
import { DataView, DataViewLayoutOptions } from 'primereact/dataview';
import { Button } from "primereact/button"
import { Inplace, InplaceDisplay, InplaceContent } from 'primereact/inplace';
import { getAllPosts, getPostById, updatePost } from "../../service/postAPI"
import { Toast } from "primereact/toast"
import { useHistory, useLocation } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { useEffect } from "react"
import { deleteContentTrainingData, getAllContentTrainingData, updateContentTrainingData } from "../../service/ContentClassificationHaNoiTrainingDataAPI"
import './DataView.css'
import ContentItem from "./contentItem"
import { Tag } from 'primereact/tag';

DataTrainingDialog.propTypes = {}

function DataTrainingDialog({ displayDialog, closeDialog, id }) {
    const [dataTraining, setDataTraining] = useState([])
    const dispatch = useDispatch()
    const token = useSelector((state) => state.user.token)
    const key = `${process.env.REACT_APP_API_URL}/content/${id}`
    const { data, isLoading } = useQuery(key, (query) => getPostById({ query, token }), {
        enabled: !!id,
    })
    const history = useHistory()
    const location = useLocation()
    const queryClient = useQueryClient()
    const toast = useRef(null)
    const [products, setProducts] = useState(null);
    const [layout, setLayout] = useState('grid');
    const [loading, setLoading] = useState(true);
    const [first, setFirst] = useState(0);
    const [totalRecords, setTotalRecords] = useState(0);
    const rows = useRef(6);
    const datasource = useRef(null);
    const isMounted = useRef(false);

    const handleClose = () => {
        closeDialog()
    }

    useEffect(() => {
        if (displayDialog) {
            getData()
        }
    }, [displayDialog])


    const getData = async () => {
        try {
            setLoading(true)
            const res = await getAllContentTrainingData({ token })
            console.log(res);
            setDataTraining(res?.docs)
            setTotalRecords(res?.total)
            setLoading(false)
        } catch (error) {
            console.log(error)
        }
    }

    const onPage = async (event) => {
        setLoading(true);

        //imitate delay of a backend call
        const startIndex = event.first;
        const currentPage = event.first / rows.current + 1;
        const endIndex = Math.min(event.first + rows.current, totalRecords - 1);
        console.log(currentPage);
        const res = await getAllContentTrainingData({ params: { page: currentPage, pageSize: 6 }, token })
        console.log(res);
        let newProducts = res?.docs;
        setFirst(startIndex);
        setDataTraining(newProducts);
        setLoading(false);
    }

    const handleChangeTag = async (data) => {
        console.log("change tag", data);
        try {
            const res = await updateContentTrainingData({ id: data.id, newData: { ...data, isHanoi: !data.isHanoi }, token })
            console.log(res);
            toast.current.show({ severity: 'success', summary: 'Success', detail: 'Update tag thành công', life: 3000 });
            //update in list
            const newData = dataTraining.map(item => {
                if (item.id === data.id) {
                    return { ...item, isHanoi: !item.isHanoi }
                }
                return item
            })
            setDataTraining(newData)
        } catch (error) {
            console.log(error);
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Update tag thất bại', life: 3000 });
        }
    }


    const handleDeleteData = async (data) => {
        console.log("delete data");
        try {
            const res = await deleteContentTrainingData({ id: data.id, token })
            console.log(res);
            toast.current.show({ severity: 'success', summary: 'Success', detail: 'Xóa dữ liệu thành công', life: 3000 });
            //update in list
            const newData = dataTraining.filter(item => item.id !== data.id)
            setDataTraining(newData)
        } catch (error) {
            console.log(error);
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Xóa dữ liệu thất bại', life: 3000 });
        }
    }

    // const renderGridItem = (data) => {
    //     const [active, setActive] = useState(false);
    //     return (
    //         <div className="col-12 md:col-4">
    //             <div className="product-grid-item card m-2">
    //                 <div className="product-grid-item-top mb-3">
    //                     <div>
    //                         <i className="pi pi-tag product-category-icon"></i>
    //                         {/* <span className="product-category">{data.category}</span> */}
    //                     </div>
    //                     {
    //                         data?.isHanoi ? <div className="cursor-pointer" onClick={() => handleChangeTag(data)}> <Tag severity="success" value="Ha Noi"></Tag> </div> : <div className="cursor-pointer" onClick={() => handleChangeTag(data)}>  <Tag severity="warning" value="Not Ha Noi"></Tag> </div>
    //                     }
    //                 </div>
    //                 <div className="product-grid-item-content">
    //                     {/* <div className="product-name">{data.link}</div> */}
    //                     {/* <p
    //                         className="text-lg font-card cursor-pointer"
    //                         style={{ whiteSpace: "pre-line", wordBreak: "break-word" }}
    //                     >
    //                         {data?.textContent?.substr(0, 500) + `${data?.textContent?.length > 500 ? "... " : ""}`}
    //                     </p> */}
    //                     <Inplace active={active} onToggle={(e) => setActive(e.value)}>
    //                         <InplaceDisplay >
    //                             <span style={{ transition: 'none', padding: 0 }} className="transition-none">
    //                                 {data?.textContent?.substr(0, 500) + `${data?.textContent?.length > 500 ? "... " : ""}`}
    //                             </span>
    //                         </InplaceDisplay>
    //                         <InplaceContent>
    //                             <div onClick={() => setActive(false)} style={{ padding: 0, cursor:'pointer' }} className="p-2">
    //                                 {data?.textContent}
    //                             </div>
    //                         </InplaceContent>
    //                     </Inplace>

    //                 </div>
    //                 <div className="product-grid-item-bottom mt-3">
    //                     <Button onClick={() => handleDeleteData(data)} icon="pi pi-trash" label="Loại bỏ" ></Button>
    //                 </div>
    //             </div>
    //         </div>
    //     );
    // }

    const itemTemplate = (product, layout) => {
        return <ContentItem data={product} handleChangeTag={handleChangeTag} handleDeleteData={handleDeleteData} />;
    }

    const renderHeader = () => {
        // let onOptionChange = (e) => {
        //     setLoading(true);
        //     setLayout(e.value);
        // };

        // return (
        //     <div style={{ textAlign: 'left' }}>
        //         <DataViewLayoutOptions layout={layout} onChange={onOptionChange} />
        //     </div>
        // );
    }

    const header = renderHeader();

    return (
        <div>
            <Toast ref={toast} />
            <Dialog
                visible={displayDialog}
                dismissableMask
                header="Data Training"
                footer={<div className="p-d-flex p-jc-end"></div>}
                // footer={footerDialog}
                className="p-fluid w-12 width-dialog"
                breakpoints={{ '960px': '75vw' }} style={{ width: '50vw' }}
                modal
                onHide={() => {
                    handleClose()
                }}
            >
                <div className="dataview-demo">
                    <div className="card">
                        <DataView value={dataTraining} layout={'grid'} header={header}
                            itemTemplate={itemTemplate} lazy paginator paginatorPosition={'both'} rows={rows.current}
                            totalRecords={totalRecords} first={first} onPage={onPage} loading={loading}
                        />
                    </div>
                </div>
            </Dialog >
        </div >
    )
}

export default DataTrainingDialog
