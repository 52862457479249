import React, {useRef} from "react"
import PropTypes from "prop-types"
import {Dialog} from "primereact/dialog"
import {useMutation, useQuery, useQueryClient} from "react-query"
import {Avatar} from "primereact/avatar"
import moment from "moment"
import {Button} from "primereact/button"
import {getAllPosts, getPostById, updatePost} from "../../service/postAPI"
import DetailPost from "../ManageTopic/DetailPost"
import {Toast} from "primereact/toast"
import {useHistory, useLocation} from "react-router-dom"
import {useDispatch, useSelector} from "react-redux"
import {clearQueryStr} from "../../store/queryStore"
import {useEffect} from "react"
DetailModelAI.propTypes = {}

function DetailModelAI({displayDialog, closeDialog, id}) {
  const dispatch = useDispatch()
  const token = useSelector((state) => state.user.token)
  const key = `${process.env.REACT_APP_API_URL}/content/${id}`
  const {data, isLoading} = useQuery(key, (query) => getPostById({query, token}), {
    enabled: !!id,
  })
  const history = useHistory()
  const location = useLocation()
  const queryClient = useQueryClient()
  const toast = useRef(null)
  const handleClose = () => {
    closeDialog()
  
  }

  return (
    <div>
      <Toast ref={toast} />
        <Dialog
          visible={displayDialog}
          dismissableMask
          // footer={footerDialog}
          className="p-fluid w-12 width-dialog dialog-fix-height"
          modal
          onHide={() => {
            handleClose()
          }}
        >
            ádsadsadsad
        </Dialog>
    </div>
  )
}

export default DetailModelAI
